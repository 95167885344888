import { create } from 'jss';
import rtl from 'jss-rtl';
import React from 'react';

import { jssPreset, StylesProvider } from '@material-ui/core/styles';

// Configure JSS
const jss = create({plugins: [...jssPreset().plugins, rtl()]});

const HealthMeStyleProvider: React.FC<React.ReactNode> = props => {
  return <StylesProvider jss={jss}>{props.children}</StylesProvider>;
};
export default HealthMeStyleProvider;
