import routes from 'modules';
import React from 'react';
import { renderRoutes } from 'react-router-config';
import AppContextPropsType from 'types/AppContextPropsType';

import AppErrorBoundary from '@healthme/core/AppErrorBoundary';
import AppSuspense from '@healthme/core/AppSuspense';
import AppContext from '@healthme/utility/AppContext';

import AppFooter from './AppFooter';
import AppHeader from './AppHeader';

const BlankLayout: React.FC<any> = () => {
	const { primary } = React.useContext<AppContextPropsType>(AppContext);

	return (
		<>
			<AppSuspense>
				<AppHeader primary={primary} baseUrl={`/`} />
				<div className="">
					<AppErrorBoundary>{renderRoutes(routes)}</AppErrorBoundary>
				</div>
				<AppFooter />
			</AppSuspense>
		</>
	);
};
export default BlankLayout;
