import React from 'react';

import Icon from '../Icon/Index';

interface Props {
	color?: 'text-white' | 'text-blue' | 'primary' | 'gray' | 'text-red';
	children?: React.ReactNode;
	onClick?: () => void;
	width?: 'large' | 'xl' | 'standard' | 'medium' | 'small' | 'w-full' | 'xs';
	type?: ButtonType;
	extraClasses?: string;
	disabled?: boolean;
	buttonType?: `button` | `submit`;
	hideLoader?: boolean;
	styles?: any;
	tabIndex?: number;
}

export type ButtonType =
	| 'primary'
	| 'secondary'
	| 'tertiary'
	| 'text-link'
	| 'danger'
	| 'text-link-danger';
const Button: React.FC<Props> = props => {
	const classes = `button ${props.type} ${props.color} ${props.width} ${
		props.disabled && ' opacity-50 '
	} relative font-semibold shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 truncate ${
		props.extraClasses
	} xs:w-[fit-content] truncate xs:overflow-ellipsis xs:overflow-hidden`;
	return (
		<button
			tabIndex={props.tabIndex || -1}
			type={props.buttonType}
			disabled={props.disabled}
			className={classes}
			onClick={() => {
				if (!props.disabled) props.onClick && props?.onClick();
			}}
			style={props.styles}
		>
			<span className="relative">
				{props.disabled && !props.hideLoader && (
					<Icon
						type="Loading"
						classNames=" inline-block w-4 absolute mr-5 -left-6 -top-0"
					/>
				)}
				{props.children}
			</span>
		</button>
	);
};
Button.defaultProps = {
	color: 'text-white',
	children: '',
	onClick: () => {},
	width: 'standard',
	type: 'primary',
	extraClasses: '',
	buttonType: 'button',
	hideLoader: false,
	styles: {},
};
export default Button;
