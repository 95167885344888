import * as React from 'react';

import Icon from '@healthme/core/Icon/Index';

export interface IRadioFieldProps {
	text: string;
	onChange: (val: string) => void;
	selected: string;
	value: string;
	className?: string;
	labelSize?: string;
	iconColor?: string;
	noIcon?: boolean;
	disabled?: boolean;
}

export default function RadioField(props: IRadioFieldProps) {
	const { selected, onChange, text, value } = props;

	return (
		<div
			className={`radio-container ${props.className} xs:my-1 ${
				props.disabled ? 'cursor-default' : ''
			}`}
			onClick={() => {
				if (props.disabled) return;
				onChange(value);
			}}
		>
			<div
				className={`radio-outer-circle inline-block ${
					value !== selected && 'unselected relative'
				} ${props.disabled ? 'bg-gray-200 cursor-default' : ''}`}
			>
				{value === selected && props.noIcon && (
					<div className={`radio-inner-circle inline-block primary bg-blue`}></div>
				)}
				{value === selected && !props.noIcon && (
					<Icon type="CircleCheck" classNames={`w-6 ${props.iconColor}`} />
				)}
			</div>
			<div className={`helper-text text-p inline-block ${props.labelSize}`}>{text}</div>
		</div>
	);
}

RadioField.defaultProps = {
	iconColor: 'icon-blue',
	noIcon: false,
	disabled: false,
};
