import moment from 'moment';

export const AUTH_ROLE = {
	admin: ['admin'],
	user: ['user', 'admin'],
};

const getApiUrl = () => {
	if (
		window.location.hostname === 'patients.healthmedocs.com' ||
		window.location.hostname === 'carenav.healthmedocs.com' ||
		window.location.hostname === 'network.healthmedocs.com'
	)
		return 'https://api.healthmedocs.com/api/v2';
	if (window.location.hostname === 'demo.healthmedocs.com') {
		return 'https://demo-api.healthmedocs.com/api/v2';
	}

	if (
		window.location.hostname === 'localhost' ||
		window.location.hostname === 'stage.healthmedocs.com' ||
		window.location.hostname === 'stage-carenav.healthmedocs.com' ||
		window.location.hostname === 'patients.healthmedocs.local' ||
		window.location.hostname === 'network.healthmedocs.local'
	) {
		return 'https://stage-api.healthmedocs.com/api/v2';
	}

	return 'https://healthmeapi.com/api/v2';
};

const getCDNUrl = () => {
	if (
		window.location.hostname === 'patients.healthmedocs.com' ||
		window.location.hostname === 'carenav.healthmedocs.com' ||
		window.location.hostname === 'network.healthmedocs.com' ||
		window.location.hostname === 'demo.healthmedocs.com'
	) {
		return 'https://media.healthmedocs.com';
	}

	if (
		window.location.hostname === 'localhost' ||
		window.location.hostname === 'stage-carenav.healthmedocs.com' ||
		window.location.hostname === 'stage.healthmedocs.com' ||
		window.location.hostname === 'patients.healthmedocs.local'
	) {
		return 'https://stage-media.healthmedocs.com';
	}

	return 'https://stage-media.healthmedocs.com';
};

const getPdfUrl = () => {
	return window.location.hostname === 'patients.healthmedocs.com' ||
		window.location.hostname === 'carenav.healthmedocs.com' ||
		window.location.hostname === 'network.healthmedocs.com'
		? 'https://healthmeapi.com'
		: 'https://stage.healthmeapi.com';
};

const getAppUrl = () => {
	if (window.location.hostname == 'patients.healthmedocs.com') {
		return 'https://patients.healthmedocs.com';
	}
	if (window.location.hostname === 'stage.healthmedocs.com') {
		return 'https://stage.healthmedocs.com';
	}

	if (window.location.hostname === 'demo.healthmedocs.com') {
		return 'https://demo.healthmedocs.com';
	}

	if (window.location.hostname === 'carenav.healthmedocs.com') {
		return 'https://carenav.healthmedocs.com';
	}
	if (window.location.hostname === 'stage-carenav.healthmedocs.com') {
		return 'https://stage-carenav.healthmedocs.com';
	}

	if (
		window.location.hostname === 'localhost' ||
		window.location.hostname === 'patients.healthmedocs.local'
	) {
		return 'http://localhost:3000';
	}

	return 'https://patients.healthmedocs.com';
};

const getGooglePayMode = () => {
	return window.location.hostname == 'patients.healthmedocs.com' ? 'PRODUCTION' : 'TEST';
};

const getGooglePayMerchantId = () => {
	return window.location.hostname == 'patients.healthmedocs.com'
		? 'fba4250d-f5df-4404-b0b3-051320092919'
		: '3df10c61-7528-429b-a3bf-a40e7843e305';
};


const getApplePayMerchantId = () => {
	return window.location.hostname == 'patients.healthmedocs.com'
		? 'fba4250d-f5df-4404-b0b3-051320092919'
		: '3df10c61-7528-429b-a3bf-a40e7843e305';
};

const getAffirmToken = () =>
	window.location.hostname === 'patients.healthmedocs.com'
		? 'HPOI05RL6E2U1K9A'
		: 'DDSQ9C3I6SG4Z3AQ';

export const GOOGLE_PAY_MODE = getGooglePayMode();
export const GOOGLE_PAY_MERCHANT_ID = getGooglePayMerchantId();
export const APPLE_PAY_MERCHANT_ID = getApplePayMerchantId();
export const INITIAL_URL = '/secure/dashboard'; // this url will open after login
export const INITIAL_PATIENT_URL = '/secure/patient/dashboard'; // this url will open after login
export const AFFIRM_TOKEN = getAffirmToken();
export const PARENT_ORGANIZATION_ID = 'b0aa2bbe-8201-48c4-9606-63bd8d998000';

export const PHONE_VALIDATION_REGEX = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;

export const BASE_URL = getApiUrl();

export const BASE_PDF_URL = getPdfUrl();

export const BASE_APP_URL = getAppUrl();
export const CDN_URL = getCDNUrl();
export const PHONE_FORMAT_REGEX = /[^0-9]+/g;
export const EXPIRE_DIFF = 45;
export const REFRESH_TIME = 1;
export const REFRESH_TIME_PART = 'minute';

export const PASSWORD_VALIDATION_REGEX =
	/^(?=.*\d)(?=.*[)(:;.,\[\]+=}{|!@#_$%~^&*\-])(?=.*[a-z])(?=.*[A-Z]).{10,}$/;

export const MAPBOX_TOKEN =
	'pk.eyJ1IjoiaGVhbHRobWVhcGkiLCJhIjoiY2xiMmhiZ21hMDFtOTN4cnB2NG04emVjaiJ9.SrEVirZTo2tPAkil4O3Uvg'; // Set your mapbox token here

export const CREDIT_CARD_MONTHS = [
	{ value: '01', label: 'January' },
	{ value: '02', label: 'February' },
	{ value: '03', label: 'March' },
	{ value: '04', label: 'April' },
	{ value: '05', label: 'May' },
	{ value: '06', label: 'June' },
	{ value: '07', label: 'July' },
	{ value: '08', label: 'August' },
	{ value: '09', label: 'September' },
	{ value: '10', label: 'October' },
	{ value: '11', label: 'November' },
	{ value: '12', label: 'December' },
];

export const CREDIT_CARD_YEARS = new Array(10)
	.fill(new Date().getFullYear())
	.map((item, idx) => ({ label: (item + idx).toString(), value: item + idx }));

export const PHONE_REGEX_FN = (value, context) => {
	if (value && value.replace('+1', '').match(PHONE_VALIDATION_REGEX)) {
		return true;
	}
	return false;
};

export const DOB_VALIDATION = (value, context) => {
	const now = moment();
	return moment(value).isBefore(now);
};

export const DEFAULT_PACKAGE_IMAGE_MAP = {
	'0': `${CDN_URL}/packages/defaults/back-neck-care-initial-evaluation.svg`,
	dme: `${CDN_URL}/packages/defaults/generic-dme.svg`,
	imaging: `${CDN_URL}/packages/defaults/generic-imaging.svg`,
	office_visit: `${CDN_URL}/packages/defaults/generic-office-visit.svg`,
	physical_therapy: `${CDN_URL}/packages/defaults/generic-physical-therapy.svg`,
	procedure: `${CDN_URL}/packages/defaults/generic-procedure.svg`,
	retail: `${CDN_URL}/packages/defaults/generic-retail.svg`,
	surgery: `${CDN_URL}/packages/defaults/generic-surgery.svg`,
	travel: `${CDN_URL}/packages/defaults/generic-travel.svg`,
};

export const RECAPTCHA_TOKEN = `6Lc-fSMpAAAAANG7TGSIll1UEB772eKu3jNo0htv`;