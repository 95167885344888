export interface NavItemProps {
	id: string;
	messageId: string;
	title: string;
	icon?: string;
	exact?: boolean;
	url?: string;
	type?: string;
	count?: number;
	color?: string;
	auth?: string[];
	children?: NavItemProps[] | NavItemProps;
	hideGFE?: Boolean;
	adminOnly?: Boolean;
}

export const patientRoutesConfig: NavItemProps[] = [
	{
		id: 'Dashboard',
		title: 'Dashboard',
		messageId: 'sidebar.secure.patient.dashboard',
		type: 'item',
		url: '/secure/patient/dashboard',
		icon: 'Dashboard',
	},
	{
		id: 'Purchases',
		title: 'Purchases',
		messageId: 'sidebar.secure.patient.purchases',
		type: 'item',
		url: '/secure/patient/purchases',
		icon: 'CreditCard',
	},
	{
		id: 'settings',
		title: 'Settings',
		messageId: 'sidebar.secure.settings',
		type: 'item',
		url: '/secure/patient/settings',
		icon: 'Gear',
	},
	{
		id: 'Logout',
		title: 'Logout',
		messageId: 'sidebar.secure.logout',
		type: 'logout',
		url: '/secure/logout',
		icon: 'Logout',
	},
];

const routesConfig: NavItemProps[] = [
	{
		id: 'Dashboard',
		title: 'Dashboard',
		messageId: 'sidebar.secure.dashboard',
		type: 'item',
		url: '/secure/dashboard',
		icon: 'Dashboard',
		hideGFE: false,
	},
	{
		id: 'Payments',
		title: 'Payments',
		messageId: 'sidebar.secure.payments',
		type: 'item',
		url: '/secure/payments',
		icon: 'CreditCard',
		hideGFE: true,
	},
	{
		id: 'Subscriptions',
		title: 'Subscriptions',
		messageId: 'sidebar.secure.subscriptions',
		type: 'item',
		url: '/secure/subscriptions',
		icon: 'Dollar',
		hideGFE: true,
		adminOnly: true,
	},
	// {
	// 	id: 'Analytics',
	// 	title: 'Analytics',
	// 	messageId: 'sidebar.secure.analytics',
	// 	type: 'item',
	// 	url: '/secure/analytics',
	// 	icon: 'Globe',
	// },
	{
		id: 'Accounts',
		title: 'Accounts',
		messageId: 'sidebar.secure.accounts',
		type: 'item',
		url: '/secure/accounts',
		icon: 'Users',
		hideGFE: true,
		auth: ['service_provider', 'admin', 'organization_manager', 'marketplace_manager', 'GFE'],
	},
	{
		id: 'Packages',
		title: 'Packages',
		messageId: 'sidebar.secure.packages',
		type: 'item',
		url: '/secure/packages',
		icon: 'Package',
		hideGFE: false,
		auth: [
			'service_provider',
			'admin',
			'organization_manager',
			'marketplace_manager',
			'staff',
			'limited_staff',
			'GFE',
		],
	},
	{
		id: 'resources',
		title: 'Resources',
		messageId: 'sidebar.secure.resources',
		type: 'item',
		url: '/secure/resources',
		icon: 'Document',
		hideGFE: false,
	},
	{
		id: 'settings',
		title: 'Settings',
		messageId: 'sidebar.secure.settings',
		type: 'item',
		url: '/secure/settings',
		icon: 'Gear',
		hideGFE: false,
		auth: ['service_provider', 'admin', 'organization_manager', 'marketplace_manager', 'GFE'],
	},
	{
		id: 'gfe',
		title: 'GFE',
		messageId: 'sidebar.secure.gfe',
		type: 'item',
		url: '/secure/gfe',
		icon: 'Document',
		hideGFE: false,
	},
	{
		id: 'onboard-tracker',
		title: 'Onboard Tracker',
		messageId: 'sidebar.secure.onboard',
		type: 'item',
		url: '/secure/onboard-tracker',
		icon: 'Users',
		hideGFE: true,
		adminOnly: true,
	},
	{
		id: 'message-center',
		title: 'Message Center',
		messageId: 'sidebar.secure.messages',
		type: 'item',
		url: '/secure/message-center',
		icon: 'Alert',
		hideGFE: true,
		adminOnly: true,
	},
	// {
	// 	id: 'release-notes',
	// 	title: 'Release Notes',
	// 	messageId: 'sidebar.secure.release_notes',
	// 	type: 'item',
	// 	url: '/secure/release-notes',
	// 	icon: 'Link',
	// 	hideGFE: false,
	// 	adminOnly: false,
	// },

	{
		id: 'Logout',
		title: 'Logout',
		messageId: 'sidebar.secure.logout',
		type: 'logout',
		url: '/secure/logout',
		icon: 'Logout',
		hideGFE: false,
	},
	{
		id: 'marketplace',
		title: 'View Marketplace',
		messageId: 'sidebar.secure.marketplace',
		type: 'marketplace',
		url: '',
		icon: 'Globe',
		hideGFE: false,
	},
];

export const routesNetworkConfig: NavItemProps[] = [
	{
		id: 'Search',
		title: 'Search',
		messageId: 'sidebar.secure.dashboard',
		type: 'item',
		url: '/secure/networks/dashboard',
		icon: 'Dashboard',
		hideGFE: false,
	},
	{
		id: 'Referrals',
		title: 'Referrals',
		messageId: 'sidebar.secure.payments',
		type: 'item',
		url: '/secure/networks/payments',
		icon: 'CreditCard',
		hideGFE: true,
	},
	{
		id: 'Accounts',
		title: 'Accounts',
		messageId: 'sidebar.secure.accounts',
		type: 'item',
		url: '/secure/networks/accounts',
		icon: 'Users',
		hideGFE: true,
	},
	// {
	// 	id: 'resources',
	// 	title: 'Resources',
	// 	messageId: 'sidebar.secure.resources',
	// 	type: 'item',
	// 	url: '/secure/networks/resources',
	// 	icon: 'Document',
	// 	hideGFE: false,
	// },
	{
		id: 'settings',
		title: 'Settings',
		messageId: 'sidebar.secure.settings',
		type: 'item',
		url: '/secure/networks/settings',
		icon: 'Gear',
		hideGFE: false,
	},

	{
		id: 'Logout',
		title: 'Logout',
		messageId: 'sidebar.secure.logout',
		type: 'logout',
		url: '/secure/logout',
		icon: 'Logout',
		hideGFE: false,
	},
	// {
	// 	id: 'marketplace',
	// 	title: 'View Network',
	// 	messageId: 'sidebar.secure.marketplace',
	// 	type: 'marketplace',
	// 	url: '',
	// 	icon: 'Globe',
	// 	hideGFE: false,
	// },
];


export const routesNetworkGroupConfig: NavItemProps[] = [
	{
		id: 'Referrals',
		title: 'Referrals',
		messageId: 'sidebar.secure.payments',
		type: 'referrals',
		url: '/secure/network-groups/referrals',
		icon: 'CreditCard',
		hideGFE: true,
	},
	{
		id: 'settings',
		title: 'Settings',
		messageId: 'sidebar.secure.settings',
		type: 'settings',
		url: '/secure/network-groups/settings',
		icon: 'Gear',
		hideGFE: false,
	},

	{
		id: 'Logout',
		title: 'Logout',
		messageId: 'sidebar.secure.logout',
		type: 'logout',
		url: '/secure/logout',
		icon: 'Logout',
		hideGFE: false,
	},
];
export default routesConfig;
