import * as React from 'react';

export interface IDividerProps {
	className?: string;
}

export default function Divider(props: IDividerProps) {
	return (
		<>
			<div className={`divide-y divide-grey-600 divide-solid px-10 my-5 ${props.className}`}>
				<div></div>
				<div></div>
			</div>
		</>
	);
}
