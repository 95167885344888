import React from 'react';

export const authRouteConfig = [
	{
		routes: [
			{
				path: '/auth/patient/signin',
				component: React.lazy(() => import('./PatientSignin')),
			},
		],
	},
	{
		routes: [
			{
				path: '/auth/patient/forget-password',
				component: React.lazy(() => import('./PatientForgetPassword')),
			},
		],
	},
	{
		routes: [
			{
				path: '/auth/patient/reset-password',
				component: React.lazy(() => import('./PatientResetPassword')),
			},
		],
	},
	{
		routes: [
			{
				path: '/auth/patient/verify',
				component: React.lazy(() => import('./PatientVerifyEmail/Index')),
			},
		],
	},
	{
		routes: [
			{
				path: '/auth/patient/signup',
				component: React.lazy(() => import('./PatientSignup')),
			},
		],
	},
	{
		routes: [
			{
				path: '/auth/signin',
				component: React.lazy(() => import('./Signin')),
			},
		],
	},

	{
		routes: [
			{
				path: '/auth/signup',
				component: React.lazy(() => import('./Signup')),
			},
		],
	},
	{
		routes: [
			{
				path: '/auth/forget-password',
				component: React.lazy(() => import('./ForgetPassword')),
			},
		],
	},
	{
		routes: [
			{
				path: '/auth/reset-password',
				component: React.lazy(() => import('./ResetPassword')),
			},
		],
	},
	{
		routes: [
			{
				path: '/auth/mfa',
				component: React.lazy(() => import('./MFA')),
			},
		],
	},
	{
		routes: [
			{
				path: '/auth/set-password',
				component: React.lazy(() => import('./SetPassword')),
			},
		],
	},
	{
		routes: [
			{
				path: '/auth/verify',
				component: React.lazy(() => import('./VerifyEmail/Index')),
			},
		],
	},
	{
		routes: [
			{
				path: '/auth/pause',
				component: React.lazy(() => import('./Pause/Index')),
			},
		],
	},
	{
		routes: [
			{
				path: '/error-pages/error-404',
				component: React.lazy(() => import('../errorPages/Error404/index')),
			},
		],
	},
];
