import React from 'react';

import Button from '@healthme/core/Button/Index';
import { SingleCaseModal } from '@healthme/core/SingleCaseModal/Index';
import { MenuItem } from '@material-ui/core';

export default function CarenavRequestDialog() {
	const [openModal, setOpenModal] = React.useState(false);
	//FNS
	/*
	 **-------------------------------------------------------------------------------------
	 ** FN NAME - handleOpenClaimModal
	 **-------------------------------------------------------------------------------------
	 */
	const handleOpenClaimModal = () => {
		setOpenModal(true);
	};

	return (
		<>
			<Button
				type="primary"
				width="medium"
				extraClasses="mr-4 lt-md:hidden"
				onClick={handleOpenClaimModal}
			>
				Single Case Request
			</Button>
			<MenuItem className="hidden lt-md:block" onClick={handleOpenClaimModal}>
				Single Case Request
			</MenuItem>
			<SingleCaseModal
				open={openModal}
				onClose={() => {
					setOpenModal(false);
				}}
			/>
		</>
	);
}
