import React from 'react';

import Button from '@healthme/core/Button/Index';
import { ClaimPaymentModal } from '@healthme/core/ClaimPaymentModal/Index';
import { MenuItem } from '@material-ui/core';

export default function ClaimPaymentDialog() {
	//hooks
	const [openClaimModal, setOpenClaimModal] = React.useState(false);

	const [selectedPackage, setSelectedPackage] = React.useState({
		id: '',
		payeee: {},
		claimed_package: {},
		amount: 0,
	});
	//FNS
	/*
	 **-------------------------------------------------------------------------------------
	 ** FN NAME - handleOpenClaimModal
	 **-------------------------------------------------------------------------------------
	 */
	const handleOpenClaimModal = () => {
		setOpenClaimModal(true);
	};
	return (
		<>
			<Button
				type="primary"
				width="medium"
				extraClasses="lt-md:hidden"
				onClick={() => handleOpenClaimModal()}
			>
				Claim Payment
			</Button>
			<MenuItem className="hidden lt-md:block" onClick={handleOpenClaimModal}>
				Claim Payment
			</MenuItem>

			<ClaimPaymentModal
				openModal={openClaimModal}
				hideRefund={true}
				onClose={() => {
					setOpenClaimModal(false);
				}}
			/>
		</>
	);
}
