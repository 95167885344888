import classNames from 'classnames';
import React, { forwardRef } from 'react';
import { CgSpinner } from 'react-icons/cg';

import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import NoSsr from '@material-ui/core/NoSsr';

import type { CSSProperties, ElementType, ReactNode } from 'react';

const Loader = ({
	showBackDrop = false,
	position = 'fixed',
	children = <></>,
}: {
	showBackDrop?: Boolean;
	position?: string;
	children?: React.ReactNode;
}) => {
	const [open, setOpen] = React.useState(false);
	React.useEffect(() => {
		setOpen(true);
	}, []);
	if (!open) return <></>;

	setTimeout(() => {
		setOpen(false);
	}, 10000);
	return (
		<NoSsr>
			<div
				style={{ zIndex: 9998, display: showBackDrop ? 'block' : 'none' }}
				className={`${position} inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full ease-in`}
			></div>
			<Box
				zIndex={9999}
				height="100%"
				display="flex"
				flex={1}
				alignItems="center"
				justifyContent="center"
				position="absolute"
				top={0}
				left={0}
				right={0}
				bottom={0}
			>
				<CircularProgress />
			</Box>
			{children}
		</NoSsr>
	);
};

export default Loader;

export interface CommonProps {
	className?: string;
	children?: ReactNode;
	style?: CSSProperties;
}

interface BaseLoadingProps extends CommonProps {
	asElement?: ElementType;
	customLoader?: ReactNode;
	loading: boolean;
	spinnerClass?: string;
}

interface LoadingProps extends BaseLoadingProps {
	type?: 'default' | 'cover';
}

const DefaultLoading = (props: BaseLoadingProps) => {
	const {
		loading,
		children,
		spinnerClass,
		className,
		asElement: Component = 'div',
		customLoader,
	} = props;

	return loading ? (
		<Component
			className={classNames(
				!customLoader && 'flex items-center justify-center h-full',
				className
			)}
		>
			{customLoader ? <>{customLoader}</> : <Spinner className={spinnerClass} size={40} />}
		</Component>
	) : (
		<>{children}</>
	);
};

const CoveredLoading = (props: BaseLoadingProps) => {
	const {
		loading,
		children,
		spinnerClass,
		className,
		asElement: Component = 'div',
		customLoader,
	} = props;

	return (
		<Component className={classNames(loading ? 'relative' : '', className)}>
			{children}
			{loading && (
				<div className="absolute inset-0 w-full h-full bg-white bg-opacity-50 dark:bg-gray-800 dark:bg-opacity-60" />
			)}
			{loading && (
				<div className="absolute z-10 transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
					{customLoader ? (
						<>{customLoader}</>
					) : (
						<Spinner className={spinnerClass} size={40} />
					)}
				</div>
			)}
		</Component>
	);
};

export const Loading = ({ type, ...rest }: LoadingProps) => {
	switch (type) {
		case 'default':
			return <DefaultLoading {...rest} />;
		case 'cover':
			return <CoveredLoading {...rest} />;
		default:
			return <DefaultLoading {...rest} />;
	}
};

Loading.defaultProps = {
	loading: false,
	type: 'default',
	asElement: 'div',
};

export interface SpinnerProps extends CommonProps {
	color?: string;
	enableTheme?: boolean;
	indicator?: ElementType;
	isSpining?: boolean;
	size?: string | number;
}

const Spinner = forwardRef((props: SpinnerProps, ref) => {
	const {
		className,
		color,
		enableTheme = true,
		indicator: Component = CgSpinner,
		isSpining = true,
		size = 20,
		style,
		...rest
	} = props;

	const spinnerColor = color;

	const spinnerStyle = {
		height: size,
		width: size,
		...style,
	};

	const spinnerClass = classNames(
		isSpining && 'animate-spin',
		spinnerColor && `text-${spinnerColor}`,
		className
	);

	return <Component ref={ref} style={spinnerStyle} className={spinnerClass} {...rest} />;
});

Spinner.displayName = 'Spinner';
