import Auth from './Auth';
import CommonReducer from './CommonReducer';
import Search from './Search';
import Settings from './Setting';

const reducers = {
	settings: Settings,
	auth: Auth,
	common: CommonReducer,
	search: Search,
};

export default reducers;
