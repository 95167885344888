import * as React from 'react';

import Box from '@material-ui/core/Box';
import NoSsr from '@material-ui/core/NoSsr';

import Icon from '../Icon/Index';
import Modal from '../Modal/Index';

export interface ISuccessMessageProps {
	open: boolean;
	message: string;
	onClose: (value: boolean) => void;
}

export default function SuccessMessage(props: ISuccessMessageProps) {
	const handleClose = e => {
		e.preventDefault();
		props.onClose(false);
	};

	return (
		<>
			<Modal maxWidth="sm" open={props.open} onClose={props.onClose}>
				<div className="z-50 bg-white text-center">
					<div className=" h-20 ml-10 mt-1 w-9/12 text-xl pt-5 relative">
						<Icon
							type="CircleCheck"
							classNames="w-6 icon-green inline mr-2  top-6 -left-7"
						/>
						{props.message}
					</div>
				</div>
			</Modal>
		</>
	);
}
