import React from 'react';

export const patientV2RouteConfig = [
	{
		routes: [
			{
				path: '/:orgSlug/:marketplaceSlug/gfe',
				component: React.lazy(() => import('./Pages/GFE/Index')),
			},
			{
				path: '/:orgSlug/:marketplaceSlug/:packageId/providers',
				component: React.lazy(() => import('./Pages/Providers/Index')),
			},
			{
				path: '/:orgSlug/:marketplaceSlug/checkout',
				component: React.lazy(() => import('./Pages/Checkout/Index')),
			},
			{
				path: '/:orgSlug/:marketplaceSlug/vcc/:vccId',
				component: React.lazy(() => import('./Pages/VCC/Index')),
			},
			{
				path: '/:orgSlug/:marketplaceSlug/order-complete',
				component: React.lazy(() => import('./Pages/OrderComplete/Index')),
			},
			{
				path: '/:orgSlug/:marketplaceSlug/gfe-complete',
				component: React.lazy(() => import('./Pages/OrderComplete/GFEComplete')),
			},
			{
				path: '/:orgSlug/:marketplaceSlug/expired',
				component: React.lazy(() => import('./Pages/Error/Expired')),
			},
			{
				path: '/:orgSlug/:marketplaceSlug/404',
				component: React.lazy(() => import('./Pages/Error/Index')),
			},
			{
				path: '/:orgSlug/:marketplaceSlug/:packageId',
				component: React.lazy(() => import('./Pages/Package/Index')),
			},
			{
				path: '/:orgSlug/:marketplaceSlug',
				component: React.lazy(() => import('./Pages/List/Index')),
			},
			{
				path: '/:orgSlug',
				component: React.lazy(() => import('./Pages/MarketplaceChooser/Index')),
			},
		],
	},
];
