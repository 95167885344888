import React from 'react';

let hasRun = false;

export default function LanguageSelector() {
	const [showLanguage, setShowLanguage] = React.useState(false);

	const googleTranslateElementInit = () => {
		if (hasRun) return;
		hasRun = true;
		try {
			if (window['google'] && window.google.hasOwnProperty('translate')) {
				const f = window.google as any;
				new f.translate.TranslateElement(
					{
						pageLanguage: 'en',
						layout: f.translate.TranslateElement.FloatPosition.TOP_LEFT,
					},
					'google_translate_element'
				);
			}
		} catch (e) {}
	};
	React.useEffect(() => {
		var addScript = document.createElement('script');
		addScript.setAttribute(
			'src',
			'//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit'
		);
		document.body.appendChild(addScript);
		window['googleTranslateElementInit'] = googleTranslateElementInit;
	}, [window]);
	return (
		<>
			<div className="flex items-center px-4 py-6 border-t border-gray-200">
				<a href="#" className="flex items-center p-2 -m-2">
					<img
						src="https://tailwindui.com/img/flags/flag-united-states.svg"
						alt=""
						className="flex-shrink-0 block w-5 h-auto"
						onClick={() => setShowLanguage(!showLanguage)}
					/>
					<span className="sr-only">, change language</span>
				</a>
				<div className={`ml-5 mt-0 ${showLanguage ? '' : 'hidden'}`}>
					<div>
						<div id="google_translate_element"></div>
					</div>
				</div>
			</div>
		</>
	);
}
