import { ApiCore, serialize } from '@healthme/services/api/utilities/Core';
import { cacheApi2 } from '@healthme/utility/Utils';

/*
 **-------------------------------------------------------------------------------------
 ** METHOD NAME - ClaimedPackages Class
 **-------------------------------------------------------------------------------------
 */
class ClaimedPackages extends ApiCore {
	/*
	 **-------------------------------------------------------------------------------------
	 ** FN NAME - getClaimedPackagesByProvider
	 **-------------------------------------------------------------------------------------
	 */
	getClaimedPackagesByProvider(marketplaceId, providerId) {
		return this.get(`?marketplace_id=${marketplaceId}&service_provider_ids[in]=${providerId}`);
	}
	/*
	 **-------------------------------------------------------------------------------------
	 ** FN NAME - getPublicPackagesByMarketplace
	 **-------------------------------------------------------------------------------------
	 */
	getPublicPackagesByMarketplace(marketplace_id, filters, count = 20) {
		const queryParams = {
			marketplace_id,
			order_by: '+sort_order',
		};
		if (filters?.keyword?.length) {
			queryParams[`title[ilike]`] = filters.keyword;
		}
		if (filters?.providers?.length) {
			queryParams[`service_provider_ids[in]`] = filters.providers.join();
		}
		if (filters?.specialities?.length) {
			queryParams[`package.speciality_ids[in]`] = filters.specialities.join();
		}
		if (filters?.types?.length) {
			queryParams[`package.type[in]`] = filters.types.join();
		}
		if (filters?.package?.length) {
			queryParams[`id`] = filters.package.join();
		}
		if (count) {
			queryParams['per_page'] = count;
		}
		const query = serialize(queryParams);
		return cacheApi2(`public/claimed_packages?${query}`, {});
	}

	/*
	 **-------------------------------------------------------------------------------------
	 ** FN NAME - getPublicPackagesByMarketplace
	 **-------------------------------------------------------------------------------------
	 */
	getPrivatePackagesByMarketplace(marketplace_id, filters, count = 20) {
		const queryParams = {
			marketplace_id,
			order_by: '+sort_order',
			active: true,
		};
		if (filters?.keyword?.length) {
			queryParams[`title[ilike]`] = filters.keyword;
		}
		if (filters?.providers?.length) {
			queryParams[`service_provider_ids[in]`] = filters.providers.join();
		}
		if (filters?.specialities?.length) {
			queryParams[`package.speciality_ids[in]`] = filters.specialities.join();
		}
		if (filters?.types?.length) {
			queryParams[`package.type[in]`] = filters.types.join();
		}
		if (filters?.package?.length) {
			queryParams[`id`] = filters.package.join();
		}
		if (count) {
			queryParams['per_page'] = count;
		}
		const query = serialize(queryParams);
		return this.getRaw(`claimed_packages?${query}`, {});
	}
}
/*
 **-------------------------------------------------------------------------------------
 ** METHOD NAME - claimedPackages
 **-------------------------------------------------------------------------------------
 */
const claimedPackages = new ClaimedPackages({
	url: 'claimed_packages',
});

export default claimedPackages;
