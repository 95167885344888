import { Link } from 'react-router-dom';

import LanguageSelector from '../PatientLayoutV2/LanguageSelector';

const AppHeader = ({
	primary = '',
	baseUrl,
	imageUrl = '/assets/logos/HealthMe_Logo_Color.png',
}) => {
	return (
		<header style={{ borderBottom: `5px solid ${primary}`, background: '#fff' }}>
			<nav aria-label="Top" className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
				<div className="border-b border-gray-200">
					<div className="flex items-center justify-between h-16">
						<div className="flex lg:ml-0">
							<Link to={baseUrl}>
								<span className="sr-only">HealthMe</span>
								<img className="w-auto h-10 lt-md:h-7" src={imageUrl} alt="" />
							</Link>
						</div>
						<div className="flex items-center ">
							<LanguageSelector />
						</div>
					</div>
				</div>
			</nav>
		</header>
	);
};
export default AppHeader;
