import { useFormik } from 'formik';
import * as React from 'react';
import { useMutation, useQuery } from 'react-query';
import { PHONE_REGEX_FN } from 'shared/constants/AppConst';
import * as Yup from 'yup';

import Button from '@healthme/core/Button/Index';
import PhoneField from '@healthme/core/Form/PhoneField/Index';
import Modal from '@healthme/core/Modal/Index';
import organizationAPI from '@healthme/services/api/organization/index';
import purchaseAPI from '@healthme/services/api/purchases/Index';
import userService from '@healthme/services/user';

import { ErrorMessage } from '../ErrorMessage/Index';
import Icon from '../Icon/Index';
import Loader from '../Loader';

export interface IResendCodeModalProps {
	openModal: boolean;
	onClose: () => void;
	showPreviousStep?: boolean;
	payment: any;
}

export function ResendCodeModal(props: IResendCodeModalProps) {
	const user = userService.getUser();
	//USE STATE
	const [resendError, setResendError] = React.useState('');

	//REACT QUERY
	const { mutate, isLoading } = useMutation((data: any) =>
		purchaseAPI.post(
			{},
			!props.payment?.vcc_id
				? `/${
						props.payment.id
				  }/notifications/send_redemption_code?send_to=${encodeURIComponent(
						data.phone
				  )}&method=sms`
				: `/${props.payment.id}/notifications/send_vcc_link?send_to=${encodeURIComponent(
						data.phone
				  )}&method=sms`
		)
	);
	const { data: orgData, isLoading: orgLoading } = useQuery(
		'dashboard',
		res => {
			return organizationAPI.get(
				`/${localStorage.getItem('organization') || user?.organization_id}`
			);
		},
		{
			enabled: props.openModal,
		}
	);

	/*
	 **-------------------------------------------------------------------------------------
	 ** FN NAME - handleModalClose
	 **-------------------------------------------------------------------------------------
	 */
	const handleModalClose = () => {
		setResendError('');
		props.onClose();
	};
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - formik
	 **-------------------------------------------------------------------------------------
	 */
	const formik = useFormik({
		validateOnChange: false,
		initialValues: {
			phone: '',
		},
		validationSchema: Yup.object({
			phone: Yup.string().test(
				'phone-validation',
				'Phone number is not valid (xxx) xxx-xxxx',
				PHONE_REGEX_FN
			),
		}),
		onSubmit: (values: any) => {
			mutate(values, {
				onError: (err: any) => {
					try {
						if (err?.data) {
							const error = JSON.parse(err.data);
							setResendError(error?.error);
						}
					} catch (e) {
						setResendError('Server Error - Please contact HealthMe Support.');
					}
				},
				onSuccess: () => {
					handleModalClose();
				},
			});
		},
	});
	if (orgLoading)
		return (
			<>
				<Loader />
			</>
		);

	return (
		<>
			<Modal
				maxWidth="xs"
				open={props.openModal}
				onClose={handleModalClose}
				title={`${!props.payment?.vcc_id ? 'Resend Code' : 'Resend VCC'} via SMS Text`}
			>
				{props.showPreviousStep && (
					<a onClick={() => handleModalClose()} className="font-semibold text-link">
						<Icon type="LeftArrow" classNames="w-2 icon-blue inline-block ml-5 mr-2" />{' '}
						Previous Step
					</a>
				)}
				{resendError.length > 0 && <ErrorMessage message={resendError} />}
				<form onSubmit={formik.handleSubmit}>
					<div className="">
						<div className="flex flex-wrap justify-center mt-2">
							<div className="w-9/12">
								<PhoneField
									label="Patient Phone Number"
									placeholder="Patient Phone Number"
									onChange={e => formik.setFieldValue('phone', `${e}`)}
									value={formik.values.phone}
									error={formik.errors.phone as string}
									name="phone"
									className="w-full"
								/>
							</div>
						</div>

						<div className="flex flex-wrap justify-center mt-2">
							<div className="w-9/12">
								<Button
									disabled={isLoading}
									buttonType="submit"
									extraClasses="w-full max-w-full"
								>
									Resend Now
								</Button>
							</div>
						</div>
					</div>
				</form>
			</Modal>
		</>
	);
}
