import cx from 'classnames';
import React, { forwardRef, HTMLAttributes, useContext } from 'react';

import { TypeaheadContext } from '../../core/Context';
import { toggleMenu } from '../../core/Typeahead';

export type InputProps = HTMLAttributes<HTMLInputElement>;

const Input = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
	const c = useContext(TypeaheadContext);
	return (
		<input
			{...props}
			className={cx('rbt-input-main', props.className)}
			ref={ref}
			onClick={e => {
				c.onMenuToggle(true);
			}}
		/>
	);
});

export default Input;
