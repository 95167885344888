import { createContext, useContext } from 'react';

import { Id, Option, OptionHandler, SelectEvent } from '../types';
import { noop } from '../utils';

export interface TypeaheadContextType {
	activeIndex: number;
	hintText: string;
	id: Id;
	initialItem: Option | null;
	inputNode: HTMLInputElement | null;
	isOnlyResult: boolean;
	onActiveItemChange: OptionHandler;
	onAdd: OptionHandler;
	onInitialItemChange: (option?: Option) => void;
	onMenuItemClick: (option: Option, event: SelectEvent<HTMLElement>) => void;
	setItem: (option: Option, position: number) => void;
	toggleMenu: () => void;
	hideMenu: () => void;
	showMenu: Boolean;
	onMenuToggle: (x) => void;
}

export const defaultContext = {
	activeIndex: -1,
	showMenu: false,
	hintText: '',
	id: '',
	initialItem: null,
	inputNode: null,
	isOnlyResult: false,
	onActiveItemChange: noop,
	onAdd: noop,
	onInitialItemChange: noop,
	onMenuItemClick: noop,
	toggleMenu: noop,
	setItem: noop,
	hideMenu: noop,
	onMenuToggle: noop,
};

export const TypeaheadContext = createContext<TypeaheadContextType>(defaultContext);

export const useTypeaheadContext = () => useContext(TypeaheadContext);
