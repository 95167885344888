import axios from 'axios';
import { BASE_URL } from 'shared/constants/AppConst';

const jwtAxios = axios.create({
	baseURL: BASE_URL,
	withCredentials: true,
});
/*
 **-------------------------------------------------------------------------------------
 ** METHOD NAME - interceptors
 **-------------------------------------------------------------------------------------
 */
jwtAxios.interceptors.response.use(
	res => res,
	err => {
		return Promise.reject(err);
	}
);
/*
 **-------------------------------------------------------------------------------------
 ** METHOD NAME - setAuthToken
 **-------------------------------------------------------------------------------------
 */
export const setAuthToken = (token: string | null) => {
	if (token) {
		jwtAxios.defaults.headers.common['x-auth-token'] = token;
		localStorage.setItem('token', token);
	} else {
		delete jwtAxios.defaults.headers.common['x-auth-token'];
		localStorage.removeItem('token');
	}
};

export default jwtAxios;
