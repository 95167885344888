import React from 'react';
import { useQuery } from 'react-query';

import Button from '@healthme/core/Button/Index';
import Icon from '@healthme/core/Icon/Index';
import Modal from '@healthme/core/Modal/Index';
import { SharePackage } from '@healthme/core/SharePackage/Index';
import claimedPackagesAPI from '@healthme/services/api/claimed_packages/Index';
import { MenuItem } from '@material-ui/core';

export default function ShareLinkDialog() {
	const [openModal, setOpenModal] = React.useState(false);
	useQuery('claimed_packages', data => {
		const queryParams = {
			page_size: 100,
			order_by: '+sort_order',
			inflate: 'true',
		};

		return claimedPackagesAPI.getAll({ queryParams });
	});

	//FNS
	/*
	 **-------------------------------------------------------------------------------------
	 ** FN NAME - handleOpenModal
	 **-------------------------------------------------------------------------------------
	 */
	const handleOpenModal = () => {
		setOpenModal(true);
	};

	/*
	 **-------------------------------------------------------------------------------------
	 ** FN NAME - handleModalClose
	 **-------------------------------------------------------------------------------------
	 */
	const handleModalClose = () => {
		setOpenModal(false);
	};

	return (
		<>
			<Button
				type="tertiary"
				color="text-blue"
				width="medium"
				extraClasses={'mr-4 lt-md:hidden'}
				onClick={handleOpenModal}
			>
				<Icon type="Link" classNames="w-4 icon-blue inline-block mr-2 -mt-1" />
				Send Link
			</Button>
			<MenuItem className="hidden lt-md:block" onClick={handleOpenModal}>
				Send Link
			</MenuItem>
			<Modal
				maxWidth="sm"
				open={openModal}
				onClose={handleModalClose}
				classNames="overflow-visible"
			>
				<SharePackage isModal />
			</Modal>
		</>
	);
}
