import { Redirect } from 'react-router-dom';

import { createRoutes } from '../@healthme/utility/Utils';
import { INITIAL_URL } from '../shared/constants/AppConst';
import { authRouteConfig } from './auth';
import { errorPagesConfigs } from './errorPages';
import { networkRouteConfig } from './network/Index';
import { patientV2RouteConfig } from './patient/Index';
import { publicRouteConfig } from './public/Index';
import { securePagesConfig } from './secure';

const routeConfigs = [
	...errorPagesConfigs,
	...publicRouteConfig,
	...authRouteConfig,
	...securePagesConfig,
	...patientV2RouteConfig,
	...networkRouteConfig,
];

const routes = [
	...createRoutes(routeConfigs),
	{
		path: '/',
		exact: true,
		component: () => <Redirect to={INITIAL_URL} />,
	},
	{
		component: () => <Redirect to="/error-pages/error-404" />,
	},
];

export default routes;
