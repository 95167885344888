import axios from 'axios';

import { ApiCore } from '@healthme/services/api/utilities/Core';
import { getNetowrkApiUrl } from '@healthme/utility/Utils';

/*
 **-------------------------------------------------------------------------------------
 ** METHOD NAME - analytics Class
 **-------------------------------------------------------------------------------------
 */
class Networks extends ApiCore {
	getCodes() {
		return axios.get('https://d2av5rd2s484ze.cloudfront.net/assets/json/codes.json');
	}
	getStates() {
		if (localStorage.getItem('states'))
			return Promise.resolve(JSON.parse(localStorage.getItem('states')));
		return axios.get('https://d2av5rd2s484ze.cloudfront.net/assets/json/2.json');
	}
	getCounties() {
		if (localStorage.getItem('counties'))
			return Promise.resolve(JSON.parse(localStorage.getItem('counties')));
		return axios.get('https://d2av5rd2s484ze.cloudfront.net/assets/json/1.json');
	}
}

/*
 **-------------------------------------------------------------------------------------
 ** METHOD NAME - networks
 **-------------------------------------------------------------------------------------
 */
const networks = new Networks({
	url: getNetowrkApiUrl('networks'),
});

export default networks;
