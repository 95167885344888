import React, { useState } from 'react';
import AppContextPropsType from 'types/AppContextPropsType';

import { Switch } from '@headlessui/react';
import { AppContext } from '@healthme';

function classNames(...classes) {
	return classes.filter(Boolean).join(' ');
}

export default function ToggleButton({
	text,
	onClick,
	initialValue = false,
	className = 'font-medium',
	disabled = false,
}) {
	const [enabled, setEnabled] = useState(initialValue);
	const { primary } = React.useContext<AppContextPropsType>(AppContext);
	React.useEffect(() => {
		setEnabled(initialValue);
	}, [initialValue]);
	/*
	 **-------------------------------------------------------------------------------------
	 ** FN NAME - setBackgroundColor
	 **-------------------------------------------------------------------------------------
	 */
	const setBackgroundColor = () => {
		// if (disabled) {
		// 	return '#bfc1c2';
		// }
		return enabled ? primary : '#bfc1c2';
	};

	return (
		<Switch.Group as="div" className="flex items-center">
			<Switch
				disabled={disabled}
				checked={enabled}
				onChange={() => {
					setEnabled(!enabled);
					onClick(!enabled);
				}}
				className={classNames(
					'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 '
				)}
				style={{
					backgroundColor: setBackgroundColor(),
					opacity: disabled ? 0.5 : 1,
				}}
			>
				<span
					aria-hidden="true"
					className={classNames(
						enabled ? 'translate-x-5' : 'translate-x-0',
						'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
					)}
				/>
			</Switch>
			<Switch.Label as="span" className="ml-3 text-sm">
				<span className={`${className} text-gray-900`}>{text}</span>
			</Switch.Label>
		</Switch.Group>
	);
}
