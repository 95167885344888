const pathArr = window.location.pathname.split('/');
const marketplace = pathArr[2] || 'network';
class Cart {
	/*
	 **-------------------------------------------------------------------------------------
	 ** FN NAME - get
	 **-------------------------------------------------------------------------------------
	 */
	get() {
		const cart = localStorage.getItem(`${marketplace}-cart`) as string;
		if (!cart) return [];
		return JSON.parse(cart);
	}
	/*
	 **-------------------------------------------------------------------------------------
	 ** FN NAME - set
	 **-------------------------------------------------------------------------------------
	 */
	set(value) {
		if (!marketplace) return;

		const currentCart = this.get();
		const isDup = currentCart.filter(i => i.id === value.id);
		if (isDup.length > 0) {
			throw 'Duplicate';
		}
		localStorage.setItem(`${marketplace}-cart`, JSON.stringify([...currentCart, value]));
	}
	/*
	 **-------------------------------------------------------------------------------------
	 ** FN NAME - set
	 **-------------------------------------------------------------------------------------
	 */
	update(value) {
		if (!marketplace) return;

		const currentCart = this.get();
		const cartItem = currentCart.filter(i => i.id === value.id);
		const updated = { ...cartItem, ...value };
		//localStorage.setItem(`${marketplace}-cart`, JSON.stringify([...currentCart, value]));
	}
	/*
	 **-------------------------------------------------------------------------------------
	 ** FN NAME - remove
	 **-------------------------------------------------------------------------------------
	 */
	remove(id) {
		const currentCart = this.get();
		localStorage.setItem(
			`${marketplace}-cart`,
			JSON.stringify(currentCart.filter(item => item.cartId !== id))
		);
	}
	/*
	 **-------------------------------------------------------------------------------------
	 ** FN NAME - clear
	 **-------------------------------------------------------------------------------------
	 */
	clear() {
		localStorage.setItem(`${marketplace}-cart`, JSON.stringify([]));
	}
}
export default new Cart();
