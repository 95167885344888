import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { BASE_URL } from 'shared/constants/AppConst';
import AppContextPropsType from 'types/AppContextPropsType';

import { AppContext } from '@healthme';
import patientService from '@healthme/services/patient/index';
import userService from '@healthme/services/user';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const user = userService.getUser();

interface props {
	usePatientLogo?: boolean;
}
const AppLogo = ({ usePatientLogo }: props) => {
	let hasRun = false;

	const { imageUrl } = useContext<AppContextPropsType>(AppContext);
	const [logoPath, setLogoPath] = React.useState('/assets/logos/HealthMe_Logo_Color.png');
	const marketplaceSlug = patientService.get('marketplaceSlug', 'marketplace');
	const orgSlug = patientService.get('orgSlug', 'org');
	const useStyles = makeStyles(() => ({
		logoRoot: {
			display: 'flex',
			flexDirection: 'row',
			cursor: 'pointer',
			alignItems: 'center',
		},
		logo: {
			marginRight: 10,
		},
	}));
	const classes = useStyles();
	const googleTranslateElementInit = () => {
		if (hasRun) return;
		hasRun = true;
		try {
			if (window['google'] && window.google.hasOwnProperty('translate')) {
				const f = window.google as any;
				new f.translate.TranslateElement(
					{
						pageLanguage: 'en',
						layout: f.translate.TranslateElement.FloatPosition.TOP_LEFT,
					},
					'google_translate_element'
				);
			}
		} catch (e) {}
	};
	React.useEffect(() => {
		var addScript = document.createElement('script');
		addScript.setAttribute(
			'src',
			'//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit'
		);
		document.body.appendChild(addScript);
		window['googleTranslateElementInit'] = googleTranslateElementInit;
	}, [window]);

	React.useEffect(() => {
		if (usePatientLogo) {
			if (imageUrl)
				setLogoPath(`${BASE_URL}/${imageUrl}` || '/assets/logos/HealthMe_Logo_Color.png');
			else setLogoPath('/assets/logos/HealthMe_Logo_Color.png');
		}
	}, [usePatientLogo]);

	/*
	 **-------------------------------------------------------------------------------------
	 ** FN NAME - logoLink
	 **-------------------------------------------------------------------------------------
	 */
	const logoLink = () => {
		let url = '/secure/dashboard';
		if (window.location.pathname.includes('patient')) url = '/patient/dashboard';
		if (user.role === 'network_manager') url = '/secure/networks/dashboard';

		return url;
	};
	return (
		<>
			{usePatientLogo && (
				<Box className={classes.logoRoot}>
					<div style={{ maxHeight: '80px' }}>
						<Link to={`/${orgSlug}/${marketplaceSlug}`}>
							<img className="patient-logo" src={logoPath} alt="healthme-logo" />
						</Link>
						<div className="absolute mr-5 -bottom-8 left-2">
							<div>
								<div id="google_translate_element"></div>
							</div>
						</div>
					</div>
				</Box>
			)}
			{!usePatientLogo && (
				<Box className={classes.logoRoot + ``}>
					<Link to={logoLink()}>
						<img className="admin-logo lt-lg:w-40" src={logoPath} alt="healthme-logo" />
					</Link>
				</Box>
			)}
		</>
	);
};

export default AppLogo;
