import { deepPurple } from '@material-ui/core/colors';
import { fade, makeStyles } from '@material-ui/core/styles';

import { HealthMeTheme } from '../../../../types/AppContextPropsType';

const useStyles = makeStyles((theme: HealthMeTheme) => ({
	appToolbar: {
		paddingLeft: 20,
		paddingRight: 20,
		minHeight: 56,
		[theme.breakpoints.up('sm')]: {
			minHeight: 80,
		},
		[theme.breakpoints.up('md')]: {
			paddingLeft: 20,
			paddingRight: 20,
		},
	},
	grow: {
		flexGrow: 1,
	},
	menuButton: {
		marginRight: theme.spacing(2),
	},
	menuIcon: {
		width: 40,
		height: 40,
	},
	title: {
		display: 'none',
		[theme.breakpoints.up('sm')]: {
			display: 'block',
		},
	},
	search: {
		position: 'relative',
		borderRadius: theme.overrides.MuiCard.root.borderRadius,
		backgroundColor: fade(theme.palette.common.white, 0.15),
		'&:hover': {
			backgroundColor: fade(theme.palette.common.white, 0.25),
		},
		marginRight: theme.spacing(2),
		marginLeft: 0,
		width: '100%',
		[theme.breakpoints.up('sm')]: {
			marginLeft: theme.spacing(3),
			width: 'auto',
		},
	},
	searchIcon: {
		width: theme.spacing(7),
		height: '100%',
		position: 'absolute',
		pointerEvents: 'none',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	inputRoot: {
		color: 'inherit',
	},
	inputInput: {
		padding: theme.spacing(1, 1, 1, 7),
		transition: theme.transitions.create('width'),
		width: '100%',
		[theme.breakpoints.up('md')]: {
			width: 200,
		},
	},
	sectionDesktop: {
		display: 'none',
		[theme.breakpoints.up('md')]: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'flex-end',
			width: '100%',
		},
	},
	sectionMobile: {
		display: 'flex',
		[theme.breakpoints.up('md')]: {
			display: 'none',
		},
	},
	menuItemRoot: {
		padding: 0,
	},
	pointer: {
		cursor: 'pointer',
	},
	middleRoot: {
		verticalAlign: 'middle',
		display: 'inline-block',
	},
	purpleAvatar: {
		margin: 10,
		color: '#fff',
		backgroundColor: deepPurple[500],
	},
	personAvatar: {
		margin: 10,
		height: 30,
		width: 30,
	},
}));
export default useStyles;
