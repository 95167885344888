import { NavStyle } from '../../../shared/constants/AppEnums';
import BlankLayout from './BlankLayout';
import DefaultLayout from './DefaultLayout';
import NetworkLayout from './NetworkLayout';
import PatientLayout from './PatientLayout';
import PatientLayoutV2 from './PatientLayoutV2';

interface LayoutsProps {
	[x: string]: any;
}

const Layouts: LayoutsProps = {
	[NavStyle.DEFAULT]: DefaultLayout,
	[NavStyle.PATIENT]: PatientLayout,
	[NavStyle.NETWORK]: NetworkLayout,
	[NavStyle.PATIENTV2]: PatientLayoutV2,
	[NavStyle.BLANK]: BlankLayout,
};
export default Layouts;
