import React from 'react';

export const networkRouteConfig = [
	{
		routes: [
			{
				path: '/n/:networkOrgSlug/:networkSlug/order-complete',
				component: React.lazy(() => import('./Pages/OrderComplete/Index')),
			},
			{
				path: '/n/:networkOrgSlug/:networkSlug/:packageId/providers',
				component: React.lazy(() => import('./Pages/Providers/Index')),
			},

			{
				path: '/n/:networkOrgSlug/:networkSlug/cart',
				component: React.lazy(() => import('./Pages/Cart/Index')),
			},
			{
				path: '/n/:networkOrgSlug/:networkSlug/:packageId/checkout',
				component: React.lazy(() => import('./Pages/Checkout/Index')),
			},
			{
				path: '/n/:networkOrgSlug/:networkSlug/:packageId',
				component: React.lazy(() => import('./Pages/Package/Index')),
			},
			{
				path: '/n/:networkOrgSlug',
				component: React.lazy(() => import('./Pages/Network/Index')),
			},
			{
				path: '/:networkOrgSlug/:networkSlug/:packageId/providers',
				component: React.lazy(() => import('./Pages/Providers/Index')),
			},
			{
				path: '/:networkOrgSlug/:networkSlug/order-complete',
				component: React.lazy(() => import('./Pages/OrderComplete/Index')),
			},
			{
				path: '/:networkOrgSlug/:networkSlug/cart',
				component: React.lazy(() => import('./Pages/Cart/Index')),
			},
			{
				path: '/:networkOrgSlug/:networkSlug/:packageId/checkout',
				component: React.lazy(() => import('./Pages/Checkout/Index')),
			},
			{
				path: '/:networkOrgSlug/:networkSlug/:packageId',
				component: React.lazy(() => import('./Pages/Package/Index')),
			},
			{
				path: '/:networkOrgSlug',
				component: React.lazy(() => import('./Pages/Network/Index')),
			},
		],
	},
];
