import * as React from 'react';

import {
	CheckCircleIcon,
	ExclamationCircleIcon,
	InformationCircleIcon,
	XCircleIcon,
} from '@heroicons/react/20/solid';

export interface IErrorMessageProps {
	message: string;
	color?: string;
	title?: string;
}

export function ErrorMessage(props: IErrorMessageProps) {
	const [color, setColor] = React.useState(props?.color || 'red');
	return (
		<div className={`rounded-md bg-${color}-50 p-4`}>
			<div className="flex">
				<div className="flex-shrink-0">
					{color == 'yellow' && (
						<ExclamationCircleIcon
							className={`h-6 w-6 ${
								props?.title ? 'mt-0.5' : 'mt-1.5'
							} text-${color}-400`}
							aria-hidden="true"
						/>
					)}
					{color == 'red' && (
						<XCircleIcon
							className={`h-6 w-6 ${
								props?.title ? 'mt-0.5' : 'mt-1.5'
							}  text-${color}-400`}
							aria-hidden="true"
						/>
					)}
					{color == 'green' && (
						<CheckCircleIcon
							className={`h-6 w-6 ${
								props?.title ? 'mt-0.5' : 'mt-1.5'
							}  text-${color}-400`}
							aria-hidden="true"
						/>
					)}
					{color == 'blue' && (
						<InformationCircleIcon
							className={`h-6 w-6 ${
								props?.title ? 'mt-0.5' : 'mt-1.5'
							}  text-${color}-400`}
							aria-hidden="true"
						/>
					)}
				</div>
				<div className="ml-3">
					{props?.title && (
						<h3 className={`text-base font-medium text-${color}-800`}>
							{props?.title}
						</h3>
					)}
					<div
						className={`mt-2 text-sm text-${color}-700`}
						dangerouslySetInnerHTML={{
							__html: props.message,
						}}
					></div>
				</div>
			</div>
		</div>
	);
}
