import './nav.css';

import React, { useMemo } from 'react';
import { NavLink, useHistory } from 'react-router-dom';

import Button from '@healthme/core/Button/Index';
import AsyncSelectField from '@healthme/core/Form/SelectField/Asnyc';
import Icon, { Icons } from '@healthme/core/Icon/Index';
import Modal from '@healthme/core/Modal/Index';
import auth from '@healthme/services/api/auth/Index';
import networksAPI from '@healthme/services/api/networks/Index';
import organizationAPI from '@healthme/services/api/organization/index';
import userService from '@healthme/services/user';
import { getNetworkUrl } from '@healthme/utility/Utils';

import { NavItemProps } from '../../../../modules/routesConfig';
import { checkPermission } from '../../../utility/Utils';

interface VerticalItemProps {
	item: NavItemProps;
	level: number;
}

const VerticalItem: React.FC<VerticalItemProps> = ({ item, level }) => {
	const user: any = userService.getUser();
	const history = useHistory();
	const hasPermission = useMemo(() => checkPermission(item.auth, user!.role), [item.auth, user]);
	const [openModal, setOpenModal] = React.useState(false);
	const [marketplaces, setMarketplaces] = React.useState<any>([]);
	const [marketplace, setMarketplace] = React.useState<any>('');
	const [width, setWidth] = React.useState<number>(window.innerWidth);

	React.useEffect(() => {
		window.addEventListener('resize', handleWindowSizeChange);
		return () => {
			window.removeEventListener('resize', handleWindowSizeChange);
		};
	}, []);

	/*
	 **-------------------------------------------------------------------------------------
	 ** FN NAME - handleWindowSizeChange
	 **-------------------------------------------------------------------------------------
	 */
	const handleWindowSizeChange = () => {
		setWidth(window.innerWidth);
	};

	/*
	 **-------------------------------------------------------------------------------------
	 ** FN NAME - handleOpenModal
	 **-------------------------------------------------------------------------------------
	 */
	const handleOpenModal = () => {
		setOpenModal(true);
	};

	/*
	 **-------------------------------------------------------------------------------------
	 ** FN NAME - handleModalClose
	 **-------------------------------------------------------------------------------------
	 */
	const handleModalClose = () => {
		setOpenModal(false);
	};
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - onLogout
	 **-------------------------------------------------------------------------------------
	 */

	const onLogout = async () => {
		try {
			await auth.logout();
		} catch (e) {
			localStorage.clear();
			window.location.href = '/auth/signin';
		}
		localStorage.clear();
		window.location.href = '/auth/signin';
	};

	/*
	 **-------------------------------------------------------------------------------------
	 ** FN NAME - onChangeMarketplace
	 **-------------------------------------------------------------------------------------
	 */
	const onChangeMarketplace = async () => {
		setOpenModal(true);
	};

	/*
	 **-------------------------------------------------------------------------------------
	 ** FN NAME - onChangeMarketplace
	 **-------------------------------------------------------------------------------------
	 */
	const onChangeNetwork = async () => {
		networksAPI.getRaw(`networks/${user.network_id}`).then(e => {
			const url = getNetworkUrl(`${e.name_slug}`, true, 0);
			if (width <= 768) {
				window.location.href = url;
			} else {
				window.open(url, '_blank')?.focus();
			}
		});
	};
	/*
	 **-------------------------------------------------------------------------------------
	 ** FN NAME - onReleaseNotes
	 **-------------------------------------------------------------------------------------
	 */
	const onReleaseNotes = async () => {
		const resp = await organizationAPI.getRaw(`auth/staff_accounts/sso/zendesk`);
		history.push('/secure/release-notes', {
			state: { jwt: resp },
		});
		return;
	};
	if (!hasPermission) {
		return null;
	}
	if (item.id === 'release-notes') {
		return (
			<div onClick={onReleaseNotes} className="flex flex-wrap content-center w-full h-14">
				<div
					className={`navigation-item text-black text-p pl-4 hover:bg-blue-50 w-full py-3 cursor-pointer`}
				>
					<Icon type={item.icon as Icons} classNames="w-5 icon-blue inline-block mr-3" />
					{item.title}
				</div>
			</div>
		);
	}
	if (item.type === 'logout') {
		return (
			<div onClick={onLogout} className="flex flex-wrap content-center w-full h-14">
				<div
					className={`navigation-item text-black text-p pl-4 hover:bg-blue-50 w-full py-3 cursor-pointer`}
				>
					<Icon type={item.icon as Icons} classNames="w-5 icon-blue inline-block mr-3" />
					{item.title}
				</div>
			</div>
		);
	}
	if (item.type === 'marketplace' && user.role != 'network_manager') {
		return (
			<>
				<div
					onClick={onChangeMarketplace}
					className="flex flex-wrap content-center w-full h-14"
				>
					<div
						className={`navigation-item text-black text-p pl-4 hover:bg-blue-50 w-full py-3 cursor-pointer`}
					>
						<Icon
							type={item.icon as Icons}
							classNames="w-5 icon-blue inline-block mr-3"
						/>
						{item.title}
					</div>
				</div>
				<Modal
					maxWidth="sm"
					open={openModal}
					onClose={handleModalClose}
					classNames="overflow-visible"
					title="Choose Marketplace"
				>
					<div className="">
						<AsyncSelectField
							selectedValue={''}
							title="Marketplace"
							name="marketplaceId"
							term="name"
							formatter={item => ({
								value: item.id,
								label: item.name,
								slug: item.name_slug,
								organization_id: item.organization_id,
							})}
							onChange={(e, data) => {
								setMarketplace(data);
							}}
							path="marketplaces"
						/>
					</div>
					<div className="text-right">
						<Button
							onClick={handleModalClose}
							type="tertiary"
							color="text-blue"
							width="small"
						>
							Cancel
						</Button>
						<Button
							onClick={async () => {
								if (marketplace) {
									const tmp = marketplaces.find(
										item => item.value === marketplace
									);
									const org = await organizationAPI.getRaw(
										`organizations/${marketplace.organization_id}`
									);

									if (width <= 768) {
										window.location.href = `/${org.name_slug}/${marketplace.slug}`;
									} else {
										window
											.open(`/${org.name_slug}/${marketplace.slug}`, '_blank')
											?.focus();
									}
									handleModalClose();
								}
							}}
							width="small"
						>
							Select
						</Button>
					</div>
				</Modal>
			</>
		);
	}
	if (item.type === 'marketplace' && user.role == 'network_manager') {
		return (
			<>
				<div
					onClick={onChangeNetwork}
					className="flex flex-wrap content-center w-full h-14"
				>
					<div
						className={`navigation-item text-black text-p pl-4 hover:bg-blue-50 w-full py-3 cursor-pointer`}
					>
						<Icon
							type={item.icon as Icons}
							classNames="w-5 icon-blue inline-block mr-3"
						/>
						{item.title}
					</div>
				</div>
			</>
		);
	}
	// if (['settings', 'referrals'].includes(item.type) && user.role == 'network_group_staff') {
	// 	return (
	// 		<>
	// 			<div
	// 				onClick={() => {
	// 					window.location.href = item.url;
	// 				}}
	// 				className="flex flex-wrap content-center w-full h-14"
	// 			>
	// 				<div
	// 					className={`navigation-item text-black text-p pl-4 hover:bg-blue-50 w-full py-3 cursor-pointer`}
	// 				>
	// 					<Icon
	// 						type={item.icon as Icons}
	// 						classNames="w-5 icon-blue inline-block mr-3"
	// 					/>
	// 					{item.title}
	// 				</div>
	// 			</div>
	// 		</>
	// 	);
	// }
	return (
		<NavLink activeClassName="nav-active" to={item.url as string}>
			<div className="flex flex-wrap content-center w-full h-14">
				<div
					className={`navigation-item text-black text-p pl-4 hover:bg-blue-50 w-full py-3 cursor-pointer`}
				>
					<Icon type={item.icon as Icons} classNames="w-5 icon-blue inline-block mr-3" />
					{item.title}
				</div>
			</div>
		</NavLink>
	);
};;

export default React.memo(VerticalItem);
