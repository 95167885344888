import React, { useCallback, useReducer } from 'react';

import routes from '../../../modules';
import {
	FooterType,
	HeaderType,
	LayoutType,
	RouteTransition,
	ThemeMode,
	ThemeStyle,
} from '../../../shared/constants/AppEnums';
import { HealthMeTheme } from '../../../types/AppContextPropsType';
import AppContext from '../AppContext';
import { contextReducer, ThemeSetting } from './ContextReducer';
import defaultConfig from './defaultConfig';

export const ContextState = {
	theme: defaultConfig.theme,
	footer: defaultConfig.footer,
	footerType: defaultConfig.footerType,
	themeMode: defaultConfig.themeMode,
	headerMode: defaultConfig.headerMode,
	themeStyle: defaultConfig.themeStyle,
	layoutType: defaultConfig.layoutType,
	isRTL: defaultConfig.theme.direction === 'rtl',
	locale: defaultConfig.locale,
	navStyle: defaultConfig.navStyle,
	rtAnim: defaultConfig.rtAnim,
	primary: defaultConfig.theme.palette.primary.main,
	sidebarColor: defaultConfig.theme.palette.sidebar.bgColor,
	secondary: defaultConfig.theme.palette.secondary.main,
	imageLogo: '/assets/logos/HealthMe_Logo_Color.png',
	cart: [],
	fees: {},
	onBoardingStep: 1,
	loading: true,
};
const ContextProvider: React.FC<React.ReactNode> = ({ children }) => {
	const [state, dispatch] = useReducer(contextReducer, ContextState, () => ContextState);

	const setFooter = (footer: boolean) => {
		dispatch({ type: ThemeSetting.SET_FOOTER, payload: footer });
	};

	const setFooterType = (footerType: FooterType) => {
		dispatch({ type: ThemeSetting.SET_FOOTER_TYPE, payload: footerType });
	};

	const updateHeaderMode = (headerMode: HeaderType) => {
		dispatch({ type: ThemeSetting.UPDATE_HEADER_MODE, payload: headerMode });
	};

	const updateThemeStyle = useCallback((themeStyle: ThemeStyle) => {
		dispatch({ type: ThemeSetting.UPDATE_THEME_STYLE, payload: themeStyle });
	}, []);

	const updateLayoutStyle = (layoutType: LayoutType) => {
		dispatch({ type: ThemeSetting.UPDATE_LAYOUT_STYLE, payload: layoutType });
	};

	const changeLocale = (locale: any) => {
		dispatch({ type: ThemeSetting.CHANGE_LOCALE, payload: locale });
	};

	const changeNavStyle = useCallback(navStyle => {
		dispatch({ type: ThemeSetting.CHANGE_NAV_STYLE, payload: navStyle });
	}, []);

	const changeRTAnim = (rtAnim: RouteTransition) => {
		dispatch({ type: ThemeSetting.CHANGE_RT_ANIM, payload: rtAnim });
	};
	const updatePrimaryColor = (primary: string) => {
		dispatch({ type: ThemeSetting.UPDATE_PRIMARY_COLOR, payload: primary });
	};
	const updateImageLogo = (imageURL: string) => {
		dispatch({ type: ThemeSetting.UPDATE_IMAGE_URL, payload: imageURL });
	};
	const updateCart = (count: any) => {
		dispatch({ type: ThemeSetting.UPDATE_CART, payload: [count] });
	};
	const updateFees = (payload: any) => {
		dispatch({ type: ThemeSetting.UPDATE_FEES, payload });
	};

	const updateOnboardingStep = (step: any) => {
		dispatch({ type: ThemeSetting.UPDATE_ONBOARDING_STEP, payload: step });
	};

	const clearCart = () => {
		dispatch({ type: ThemeSetting.UPDATE_CART, payload: [] });
		dispatch({ type: ThemeSetting.CLEAR_CART, payload: [] });
	};

	const updateSidebarColor = (sidebarColor: string) => {
		dispatch({ type: ThemeSetting.UPDATE_SIDEBAR_COLOR, payload: sidebarColor });
	};

	const updateSecondaryColor = (secondary: string) => {
		dispatch({ type: ThemeSetting.UPDATE_SECONDARY_COLOR, payload: secondary });
	};

	const updateThemeMode = useCallback((themeMode: ThemeMode) => {
		dispatch({ type: ThemeSetting.UPDATE_THEME_MODE, payload: themeMode });
	}, []);
	const updateTheme = (theme: HealthMeTheme) => {
		dispatch({ type: ThemeSetting.UPDATE_THEME, payload: theme });
	};

	const setRTL = useCallback(rtl => {
		dispatch({ type: ThemeSetting.SET_RTL, payload: rtl });
	}, []);

	const setPageLoading = useCallback(loading => {
		dispatch({ type: ThemeSetting.SET_LOADING, payload: loading });
	}, []);
	return (
		<AppContext.Provider
			value={{
				...state,
				routes,
				updateLayoutStyle,
				rtlLocale: defaultConfig.rtlLocale,
				setRTL,
				updateSidebarColor,
				setFooter,
				setFooterType,
				updateThemeStyle,
				updateTheme,
				updateHeaderMode,
				updateThemeMode,
				updatePrimaryColor,
				updateSecondaryColor,
				changeLocale,
				changeNavStyle,
				changeRTAnim,
				updateImageLogo,
				updateCart,
				clearCart,
				setPageLoading,
				updateOnboardingStep,
				updateFees,
			}}
		>
			{children}
		</AppContext.Provider>
	);
};

export default ContextProvider;
