import routes from 'modules';
import React, { useContext, useEffect, useState } from 'react';
import { renderRoutes } from 'react-router-config';
import { useHistory, useLocation } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { RouteTransition } from 'shared/constants/AppEnums';
import AppContextPropsType from 'types/AppContextPropsType';

import { AppSuspense, Loader } from '@healthme';
import AppErrorBoundary from '@healthme/core/AppErrorBoundary';
import marketplacesAPI from '@healthme/services/api/marketplaces/Index';
import organizationAPI from '@healthme/services/api/organization/index';
import cartService from '@healthme/services/cart/index';
import patientService from '@healthme/services/patient/index';
import Box from '@material-ui/core/Box';

import AppContext from '../../../utility/AppContext';
import AppHeader from './AppHeader';
import useStyles from './index.style';

interface TransitionWrapperProps {
	children: any;
}

interface DefaultLayoutProps {}
const TransitionWrapper: React.FC<TransitionWrapperProps> = ({ children }) => {
	const { rtAnim } = useContext<AppContextPropsType>(AppContext);
	const location = useLocation();

	if (rtAnim === RouteTransition.NONE) {
		return <>{children}</>;
	}
	return (
		<TransitionGroup appear enter exit>
			<CSSTransition
				key={location.key}
				timeout={{ enter: 300, exit: 300 }}
				classNames={rtAnim}
			>
				{children}
			</CSSTransition>
		</TransitionGroup>
	);
};

const DefaultLayout: React.FC<DefaultLayoutProps> = () => {
	const [isLoading, setIsLoading] = useState(true);
	const [is404Error, setIs404Error] = useState(false);
	const history = useHistory();
	const {
		themeStyle,
		footer,
		layoutType,
		footerType,
		updatePrimaryColor,
		updateImageLogo,
		updateCart,
		clearCart,
	} = useContext<AppContextPropsType>(AppContext);
	const classes = useStyles({ themeStyle, footer });

	/*
	 **-------------------------------------------------------------------------------------
	 ** FN NAME - updateMarketplaceAttributes
	 **-------------------------------------------------------------------------------------
	 */
	const updateMarketplaceAttributes = () => {
		const marketplace = patientService.getPatientMarketplace();
		if (updatePrimaryColor) updatePrimaryColor(marketplace.primary_color);
		if (updateImageLogo) updateImageLogo(marketplace.image_path);
	};

	useEffect(() => {
		const orgSlug = window.location.pathname.split('/')[1];
		const savedOrgSlug = patientService.get('orgSlug', 'org');
		if (updateCart && cartService.get().length) {
			updateCart(cartService.get().length);
		}
		if (orgSlug !== savedOrgSlug) {
			patientService.clear();
			if (clearCart) {
				clearCart();
				cartService.clear();
			}
		}
		const marketplaceSlug = patientService.get('marketplaceSlug', 'marketplace');
		const pathArr = window.location.pathname.split('/');

		if (pathArr[1] == 'n') {
			setIsLoading(false);
			return;
		}

		if (marketplaceSlug && patientService.getPatientMarketplace() && pathArr[2]) {
			if (marketplaceSlug != pathArr[2]) {
				patientService.clear();
			} else {
				updateMarketplaceAttributes();
				setIsLoading(false);
				//return;
			}
		}
		organizationAPI.getRaw(`public/organizations/${orgSlug}`).then(async data => {
			if (!data) {
				setIs404Error(true);
				setIsLoading(false);
				return;
			}
			setIs404Error(false);
			patientService.setPatientOrganization(data);
			let marketplaces = await marketplacesAPI.getRaw(`public/marketplaces`, {
				queryParams: {
					organization_id: data.id,
				},
			});
			if (pathArr[2]) {
				let tmp = marketplaces.data.filter(item => item.name_slug === pathArr[2]);
				if (tmp.length && tmp.length === 1) {
					marketplaces.data = tmp;
				}
			}

			if (marketplaces.data.length === 1) {
				patientService.set(
					'marketplaces',
					JSON.stringify(marketplaces.data),
					'marketplace'
				);
				handleClose(marketplaces.data[0]);
				return;
			}
			if (marketplaces.data.length === 0) {
				setIs404Error(true);
				setIsLoading(false);
				return;
			}
			if (clearCart) {
				clearCart();
				cartService.clear();
			}
			if (marketplaces.data.length > 1) {
				patientService.set('openPicker', '1', 'org');
				patientService.set(
					'marketplaces',
					JSON.stringify(marketplaces.data),
					'marketplace'
				);
				patientService.set(
					'pickerMarketplaces',
					JSON.stringify(marketplaces.data),
					'marketplace'
				);
				handleClose(marketplaces.data[0]);
				return;
			}
		});
	}, [window.location.pathname]);
	/*
	 **-------------------------------------------------------------------------------------
	 ** FN NAME - handleClose
	 **-------------------------------------------------------------------------------------
	 */
	const handleClose = marketplace => {
		patientService.setPatientMarketplace(marketplace);
		updateMarketplaceAttributes();

		let packageId = '',
			extras = '';
		if (window.location.pathname.split('/')[3]) {
			packageId = `/${window.location.pathname.split('/')[3]}`;
		}
		if (window.location.pathname.split('/')[4]) {
			extras = `/${window.location.pathname.split('/')[4]}`;
		}

		history.push(
			`/${patientService.get('orgSlug', 'org')}/${patientService.get(
				'marketplaceSlug',
				'marketplace'
			)}${packageId}${extras}${window.location.search}`
		);
		// window.location.href = `/${patientService.get('orgSlug', 'org')}/${patientService.get(
		// 	'marketplaceSlug',
		// 	'marketplace'
		// )}${packageId}${window.location.search}`;
		setIsLoading(false);
	};

	if (isLoading) return <Loader />;
	if (is404Error)
		return <div className="flex content-center justify-center h-screen">404 Error</div>;
	return (
		<>
			<AppHeader />
			<Box
				className={classes.mainContent + ' mt-0 bg-white lt-md:px-5 pb-48'}
				style={{ minHeight: '800px' }}
			>
				<AppSuspense>
					<AppErrorBoundary>
						<TransitionWrapper>{renderRoutes(routes)}</TransitionWrapper>
					</AppErrorBoundary>
				</AppSuspense>
			</Box>
			<div className="w-full h-48 noprint" style={{ backgroundColor: '#202140' }}>
				<div className="w-4/12 m-auto">
					<div className="flex flex-wrap justify-around text-white">
						<div className="mt-10 mb-6">
							<img
								src="/assets/logos/HealthMe_Logo_Color.png"
								className="object-left gt-xs:h-8"
							/>
						</div>
					</div>

					<div className="flex flex-wrap justify-center gap-4 font-semibold text-white text-p">
						<div>
							<a href="https://www.healthmedocs.com/terms-conditions" target="_blank">
								How It Works
							</a>
						</div>
						{/* <div>
							<a href="#">Patient FAQ</a>
						</div> */}
						<div>
							<a href="mailto:support@healthmedocs.com">Contact Us</a>
						</div>
					</div>
					<div className="py-3 text-center text-white border-t">
						<p className="text-sm">&copy; 2023 HealthMe, Inc. All rights reserved.</p>
					</div>
				</div>
			</div>
		</>
	);
};

export default DefaultLayout;
