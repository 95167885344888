import 'react-phone-input-2/lib/style.css';

import React from 'react';

import Icon, { Icons } from '@healthme/core/Icon/Index';
import PhoneInput from '@healthme/core/react-phone-field';

//type InputElement = HTMLInputElement | HTMLTextAreaElement;
//type InputChangeEvent = React.ChangeEvent<InputElement>;

interface TextFieldProps {
	value: string;
	label: string;
	onChange: (val: string) => void;
	placeholder?: string;
	autoFocus?: boolean;
	name?: string;
	type?: 'email' | 'password' | 'text' | 'number' | 'date';
	textarea?: boolean;
	leftIcon?: Icons;
	rightIcon?: Icons;
	error?: string;
	forwardedRef?: any;
	className?: string;
	readonly?: boolean;
	width?: 'w-full';
	disabled?: boolean;
	max?: string;
	inputClasses?: string;
	showDate?: boolean;
	useMargin: boolean;
	inputStyle?: { [key: string]: any };
}

const PhoneField = ({ inputStyle = {}, ...rest }: TextFieldProps) => {
	const [value, setValue] = React.useState<any>('');
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - Return
	 **-------------------------------------------------------------------------------------
	 */
	return (
		<div className={`relative  ${rest.useMargin && 'mb-9'} ${rest?.className}`}>
			<PhoneInput
				inputStyle={{
					border: '1px solid #757575',
					padding: '16px 16px 16px 50px',
					margin: '0px 0px 16px 0px',
					fontFamily: "'Source Sans Pro', sans-serif",
					borderRadius: '4px',
					fontSize: '16px',
					width: '100%',
					height: '48px',
					...inputStyle,
				}}
				country="us"
				placeholder={rest.placeholder}
				onlyCountries={['us', 'ca']}
				value={rest.value.replace('+1', '')}
				onChange={e => {
					const p = e.replace(/[^a-z0-9-]/g, '');
					rest.onChange('+' + p);
				}}
				onBlur={e => {
					const p = e.currentTarget.value.replace(/[^a-z0-9]/g, '');
					rest.onChange('+1' + p);
				}}
				disabled={rest.disabled}
			/>
			{rest.error && <Icon type="Alert" classNames="w-4 right-icon icon-red" />}
			{rest.error && <p className="text-red-600 error-msg">{rest?.error}</p>}
		</div>
	);
};
PhoneField.defaultProps = {
	inputClasses: '',
	showDate: false,
	useMargin: true,
};
export default PhoneField;
