import './footer.css';

export default function AppFooter() {
	return (
		<footer
			style={{ background: '#315074' }}
			className="border-t border-gray-300 uui-footer03_component"
		>
			<div className="uui-page-padding">
				<div className="text-gray-500 uui-container-large">
					<div className="relative grid grid-cols-2 py-5 lt-md:pb-0 uui-padding-vertical-xlarge">
						<div className="w-layout-grid uui-footer03_top-wrapper">
							<div className="uui-footer03_left-wrapper">
								<a
									href="https://www.healthmedocs.com/"
									className="mb-5 uui-footer03_logo-link w-nav-brand"
								>
									<div className="uui-logo_component">
										<img
											src="/assets/logos/HealthMe_Logo_Color.png"
											loading="lazy"
											alt="Untitled UI logotext"
											className="uui-logo_logotype"
										/>
										<img
											src="/assets/logos/HealthMe_Logo_Color.png"
											loading="lazy"
											alt="Logo"
											className="uui-logo_image"
										/>
									</div>
								</a>
								<div className="uui-footer03_details-wrapper">
									<div className="text-gray-300 uui-text-size-medium">
										1250 Pine Ridge Road Naples FL, 34108
									</div>
									<div className="mt-1 div-block-3 footer-contact">
										<a
											href="tel:18889943627"
											className="text-gray-300 footer-contact-link"
										>
											+1-888-994-3627
										</a>
										<a
											href="mailto:support@healthmedocs.com"
											className="footer-contact-link"
										>
											support@healthmedocs.com
										</a>
									</div>
								</div>
							</div>
						</div>
						<div className="relative flex justify-end w-full text-right">
							<div
								id="w-node-_0a3e12a5-1482-5fd4-8145-98469941769b-9941768b"
								className="absolute bottom-0 flex justify-end w-layout-grid uui-footer03_menu-wrapper"
							>
								<div>
									<div className="w-layout-grid uui-footer03_social-list">
										<a
											href="http://www.twitter.com/healthmedoctors"
											target="_blank"
											className="uui-footer03_social-link w-inline-block"
										>
											<div className="social-icon w-embed">
												<svg
													width="24"
													height="24"
													viewBox="0 0 24 24"
													fill="none"
													xmlns="http://www.w3.org/2000/svg"
												>
													<path
														d="M7.55016 21.75C16.6045 21.75 21.5583 14.2467 21.5583 7.74186C21.5583 7.53092 21.5536 7.3153 21.5442 7.10436C22.5079 6.40746 23.3395 5.54425 24 4.5553C23.1025 4.9546 22.1496 5.21538 21.1739 5.32874C22.2013 4.71291 22.9705 3.74547 23.3391 2.60577C22.3726 3.17856 21.3156 3.58261 20.2134 3.80061C19.4708 3.01156 18.489 2.48912 17.4197 2.31405C16.3504 2.13899 15.2532 2.32105 14.2977 2.8321C13.3423 3.34314 12.5818 4.15471 12.1338 5.14131C11.6859 6.12792 11.5754 7.23462 11.8195 8.2903C9.86249 8.19209 7.94794 7.6837 6.19998 6.7981C4.45203 5.91249 2.90969 4.66944 1.67297 3.14952C1.0444 4.23324 0.852057 5.51565 1.13503 6.73609C1.418 7.95654 2.15506 9.02345 3.19641 9.71999C2.41463 9.69517 1.64998 9.48468 0.965625 9.10592V9.16686C0.964925 10.3041 1.3581 11.4066 2.07831 12.2868C2.79852 13.167 3.80132 13.7706 4.91625 13.995C4.19206 14.1931 3.43198 14.222 2.69484 14.0794C3.00945 15.0574 3.62157 15.9129 4.44577 16.5263C5.26997 17.1398 6.26512 17.4806 7.29234 17.5012C5.54842 18.8711 3.39417 19.6141 1.17656 19.6106C0.783287 19.61 0.390399 19.5859 0 19.5384C2.25286 20.9837 4.87353 21.7514 7.55016 21.75Z"
														fill="currentColor"
													></path>
												</svg>
											</div>
										</a>
										<a
											href="http://www.linkedin.com/company/healthmedocs"
											target="_blank"
											className="uui-footer03_social-link w-inline-block"
										>
											<div className="social-icon w-embed">
												<svg
													width="24"
													height="24"
													viewBox="0 0 24 24"
													fill="none"
													xmlns="http://www.w3.org/2000/svg"
												>
													<path
														d="M22.2234 0H1.77187C0.792187 0 0 0.773438 0 1.72969V22.2656C0 23.2219 0.792187 24 1.77187 24H22.2234C23.2031 24 24 23.2219 24 22.2703V1.72969C24 0.773438 23.2031 0 22.2234 0ZM7.12031 20.4516H3.55781V8.99531H7.12031V20.4516ZM5.33906 7.43438C4.19531 7.43438 3.27188 6.51094 3.27188 5.37187C3.27188 4.23281 4.19531 3.30937 5.33906 3.30937C6.47813 3.30937 7.40156 4.23281 7.40156 5.37187C7.40156 6.50625 6.47813 7.43438 5.33906 7.43438ZM20.4516 20.4516H16.8937V14.8828C16.8937 13.5562 16.8703 11.8453 15.0422 11.8453C13.1906 11.8453 12.9094 13.2937 12.9094 14.7891V20.4516H9.35625V8.99531H12.7687V10.5609H12.8156C13.2891 9.66094 14.4516 8.70938 16.1813 8.70938C19.7859 8.70938 20.4516 11.0813 20.4516 14.1656V20.4516Z"
														fill="currentColor"
													></path>
												</svg>
											</div>
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="w-full py-5 mt-5 uui-footer03_bottom-wrapper">
						<div className="text-gray-300 uui-text-size-small text-color-gray500">
							© 2023 HealthMe. All rights reserved.
						</div>
						<div className="w-layout-grid uui-footer03_legal-list">
							<a
								href="https://www.healthmedocs.com/privacy-policy"
								className="uui-footer03_legal-link"
							>
								Privacy Policy
							</a>
							<span className="text-gray-400">|</span>
							<a
								href="https://www.healthmedocs.com/terms-conditions"
								className="uui-footer03_legal-link"
							>
								Terms &amp;&nbsp;Conditions
							</a>
							<span className="text-gray-400">|</span>
							<a
								href="https://www.healthmedocs.com/refund-policy"
								className="uui-footer03_legal-link"
							>
								Refund Policy
							</a>
						</div>
					</div>
				</div>
			</div>
		</footer>
	);
}
