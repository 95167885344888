import { AppActions } from '../../types';
import { UPDATE_SEARCH } from '../../types/actions/Search.actions';

const INIT_STATE: { search: any | null } = {
	search: null,
};

const Search = (state = INIT_STATE, action: AppActions) => {
	switch (action.type) {
		case UPDATE_SEARCH: {
			return {
				...state,
				search: action.payload,
			};
		}
		default:
			return state;
	}
};
export default Search;
