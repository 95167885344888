import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { AppState } from 'redux/store';
import * as Yup from 'yup';

import Modal from '@healthme/core/Modal/Index';
import networkApi from '@healthme/services/api/networks/Index';
import userService from '@healthme/services/user';
import { errorParser } from '@healthme/utility/Utils';

import Button from '../Button/Index';
import SelectField from '../Form/SelectField/Index';
import TextField from '../Form/TextField/Index';

export function SingleCaseModal({ open, onClose }) {
	const [error, setError] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const { search } = useSelector<AppState, AppState['search']>(({ search }) => search);
	const user = userService.getUser();

	useEffect(() => {
		formik.setValues({
			...formik.values,
			location: search?.location || '',
			service: search?.selectedPackage || '',
		});
	}, [search]);
	useEffect(() => {
		setError('');
	}, []);
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - formik
	 **-------------------------------------------------------------------------------------
	 */
	const formik = useFormik({
		validateOnChange: false,
		initialValues: {
			name: `${user?.first_name || ''} ${user?.last_name || ''}`,
			email: user.email,
			location: search?.location || '',
			service: search?.selectedPackage || '',
			urgency: '',
		},
		validationSchema: Yup.object({
			name: Yup.string().trim().required('Required'),
			email: Yup.string().trim().email().required('Required'),
			location: Yup.string().trim().required('Required'),
			service: Yup.string().trim().required('Required'),
			urgency: Yup.string().trim().required('Required'),
		}),
		onSubmit: (data: any) => {
			networkApi
				.postRaw(
					{
						name: data.name,
						package: data.service,
						urgency: data.urgency,
						zipcode: data.location,
					},
					`network_organizations/${user.network_organization_id}/case_request`
				)
				.then(() => {
					handleModalClose();
				})
				.catch(e => {
					setError(errorParser(e));
					setIsLoading(false);
				});
		},
	});
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - formik
	 **-------------------------------------------------------------------------------------
	 */
	const handleModalClose = () => {
		formik.resetForm();
		onClose();
	};

	return (
		<>
			<Modal maxWidth="xs" open={open} onClose={handleModalClose} title="Single Case Request">
				<form onSubmit={formik.handleSubmit}>
					<div className="flex flex-wrap justify-center mt-1">
						<div className="w-9/12">
							<TextField
								label="Name"
								placeholder=" "
								onChange={formik.handleChange}
								value={formik.values.name}
								error={formik.errors.name}
								name="name"
								className="w-full"
							/>
						</div>
					</div>
					<div className="flex flex-wrap justify-center mt-1">
						<div className="w-9/12">
							<TextField
								label="Email"
								placeholder=" "
								onChange={formik.handleChange}
								value={formik.values.email}
								error={formik.errors.email}
								name="email"
								className="w-full"
							/>
						</div>
					</div>
					<div className="flex flex-wrap justify-center mt-1">
						<div className="w-9/12">
							<TextField
								label="What are you looking for?"
								placeholder=" "
								onChange={formik.handleChange}
								value={formik.values.service}
								error={formik.errors.service}
								name="service"
								className="w-full"
							/>
						</div>
					</div>
					<div className="flex flex-wrap justify-center mt-1">
						<div className="w-9/12">
							<TextField
								label="Where do you need this service?"
								placeholder=" "
								onChange={formik.handleChange}
								value={formik.values.location}
								error={formik.errors.location}
								name="location"
								className="w-full"
							/>
						</div>
					</div>
					<div className="flex flex-wrap justify-center mt-1">
						<div className="w-9/12">
							<SelectField
								title="Urgency"
								list={[
									{ value: 'high', label: 'High' },
									{ value: 'medium', label: 'Medium' },
									{ value: 'low', label: 'Low' },
								]}
								onChange={e => formik.setFieldValue('urgency', e)}
								value={formik.values.urgency}
								error={formik.errors.urgency}
								name="urgency"
								className="w-full"
								dropup={true}
							/>
						</div>
					</div>
					<div className="flex justify-end mt-5 gap-x-5">
						<Button
							onClick={handleModalClose}
							buttonType="button"
							type="tertiary"
							color="text-blue"
							extraClasses="w-full max-w-full"
						>
							Cancel
						</Button>
						<Button
							disabled={isLoading}
							buttonType="submit"
							type="primary"
							color="text-white"
							extraClasses="w-full max-w-full"
						>
							Submit Request
						</Button>
					</div>
				</form>
			</Modal>
		</>
	);
}
