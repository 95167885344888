import React from 'react';
import { useSelector } from 'react-redux';

import { Loader } from '../..';
import { AppState } from '../../../redux/store';
import { ErrorMessage } from '../ErrorMessage/Index';
import SuccessMessage from '../SuccessMessage/Index';

interface InfoViewProps {}

const InfoView: React.FC<InfoViewProps> = () => {
	const { error, loading, message } = useSelector<AppState, AppState['common']>(
		({ common }) => common
	);
	const showMessage = () => {
		return <SuccessMessage open={true} onClose={() => {}} message={message.toString()} />;
		//return <MessageView variant='success' message={message.toString()} />;
	};

	const showError = () => {
		return (
			<div className="my-5">
				<ErrorMessage message={error.toString()} />
			</div>
		);
		//return <MessageView variant='error' message={error.toString()} />;
	};

	return (
		<>
			{loading && <Loader />}
			{message && showMessage()}
			{error && showError()}
		</>
	);
};

export default InfoView;
