import axios from 'axios';
import { BASE_URL } from 'shared/constants/AppConst';

import { ApiCore } from '@healthme/services/api/utilities/Core';
import { apiProvider } from '@healthme/services/api/utilities/Provider';

/*
 **-------------------------------------------------------------------------------------
 ** METHOD NAME - Auth Class
 **-------------------------------------------------------------------------------------
 */
class Auth extends ApiCore {
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - logout
	 **-------------------------------------------------------------------------------------
	 */
	logout() {
		const headers = {};

		const appAxios = axios.create({
			baseURL: BASE_URL,
			withCredentials: true,
		});

		return appAxios.post(
			`${BASE_URL}/auth/staff_accounts/logout`,
			{},
			{
				headers,
			}
		);
	}
}

/*
 **-------------------------------------------------------------------------------------
 ** METHOD NAME - auth
 **-------------------------------------------------------------------------------------
 */
const auth = new Auth({
	url: 'auth',
});

export default auth;
