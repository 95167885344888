import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchStart, fetchSuccess } from '../../redux/actions';
import { AppState } from '../../redux/store';
import { UPDATE_AUTH_USER } from '../../types/actions/Auth.actions';
import { AuthUser } from '../../types/models/AuthUser';

//import jwtAxios from '../services/auth/jwt-auth/jwt-api';

export const useAuthToken = (): [boolean, AuthUser | null] => {
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(true);
	const { user } = useSelector<AppState, AppState['auth']>(({ auth }) => auth);
	useEffect(() => {
		const validateAuth = async () => {
			dispatch(fetchStart());
			try {
				const userData = JSON.parse(atob(localStorage.getItem('user') as string));
				dispatch({
					type: UPDATE_AUTH_USER,
					payload: {
						...userData,
					},
				});
				dispatch(fetchSuccess());
				return;
			} catch (err) {
				dispatch(fetchSuccess());
				return;
			}
		};

		const checkAuth = () => {
			Promise.all([validateAuth()]).then(() => {
				setLoading(false);
			});
		};
		checkAuth();
	}, [dispatch]);
	return [loading, user];
};

export const useAuthUser = (): AuthUser | null => {
	const { user } = useSelector<AppState, AppState['auth']>(({ auth }) => auth);
	if (user) {
		return user;
	}
	return null;
};
