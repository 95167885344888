import React from 'react';

import Tooltip from '@material-ui/core/Tooltip';

export type Icons =
	| 'Alert'
	| 'Attachment'
	| 'Bell'
	| 'Calendar'
	| 'CalendarPlus'
	| 'Camera'
	| 'Chart'
	| 'Checkmark'
	| 'CircleCheck'
	| 'Clock'
	| 'Close'
	| 'Carot'
	| 'CreditCard'
	| 'Dashboard'
	| 'Document'
	| 'DownArrow'
	| 'Envelope'
	| 'Filters'
	| 'Gear'
	| 'Globe'
	| 'HamburgerMenu'
	| 'Heart'
	| 'Info'
	| 'LeftArrow'
	| 'Link'
	| 'Location'
	| 'Lock'
	| 'Logout'
	| 'Map'
	| 'Message'
	| 'Drag'
	| 'MinusSign'
	| 'Package'
	| 'Pencil'
	| 'Play'
	| 'PlusSign'
	| 'RightArrow'
	| 'Search'
	| 'SMS'
	| 'Loading'
	| 'Target'
	| 'Three-Dots'
	| 'UpArrow'
	| 'User'
	| 'Sort'
	| 'Users'
	| 'Video'
	| 'visa'
	| 'amex'
	| 'dicover'
	| 'mastercard'
	| 'generic'
	| 'Dollar'
	| 'Cart'
	| 'Eye'
	| 'mri'
	| 'office_visit'
	| 'physical_therapy'
	| 'procedure'
	| 'surgery'
	| 'Menu'
	| 'Dots'
	| 'Info-light';

interface Props {
	type: Icons;
	classNames?: string;
	title?: any;
	icon?: React.ReactNode;
	onClick?: (e: any) => void;
}

const Icon: React.FC<Props> = props => {
	const handleClick = e => {
		props.onClick && props.onClick(e);
	};
	const [open, setOpen] = React.useState(false);
	const handleTooltipOpen = () => {
		setOpen(true);
	};
	const handleTooltipClose = () => {
		setOpen(false);
	};

	return (
		<>
			{props.title && props.title.length && (
				<>
					<Tooltip
						className="hidden gt-sm:inline-block"
						title={
							<h1 style={{ fontSize: '14px', lineHeight: '20px' }}>
								{props.title || ''}
							</h1>
						}
						arrow
					>
						<img
							onClick={handleClick}
							src={`/assets/icons/${props.type}.svg`}
							className={props.classNames}
							alt={props.type}
						/>
					</Tooltip>
					<span onClick={handleTooltipOpen}>
						<Tooltip
							onClose={handleTooltipClose}
							open={open}
							className="hidden lt-md:block"
							title={
								<h1 style={{ fontSize: '14px', lineHeight: '20px' }}>
									{props.title || ''}
								</h1>
							}
							arrow
						>
							<img
								onClick={handleClick}
								src={`/assets/icons/${props.type}.svg`}
								className={props.classNames}
								alt={props.type}
							/>
						</Tooltip>
					</span>
				</>
			)}

			{!props.title && (
				<img
					onClick={handleClick}
					src={`/assets/icons/${props.type}.svg`}
					className={props.classNames}
					alt={props.type}
				/>
			)}
		</>
	);
};
Icon.defaultProps = {
	type: 'Alert',
};
export default Icon;
