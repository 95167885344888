import clsx from 'clsx';
import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Box from '@material-ui/core/Box';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';

import { toggleNavCollapsed } from '../../../../redux/actions';
import { AppState } from '../../../../redux/store';
import { ThemeStyle } from '../../../../shared/constants/AppEnums';
import AppContextPropsType from '../../../../types/AppContextPropsType';
import AppContext from '../../../utility/AppContext';
import Navigation from '../../Navigation/VerticleNav';
import Scrollbar from '../../Scrollbar';
import useStyles from './AppSidebar.style';

interface AppSidebarProps {
	variant?: string;
	position?: 'left' | 'bottom' | 'right' | 'top';
}

const AppSidebar: React.FC<AppSidebarProps> = ({ position = 'left', variant = '' }) => {
	const { themeStyle, themeMode } = useContext<AppContextPropsType>(AppContext);

	const dispatch = useDispatch();
	const { navCollapsed } = useSelector<AppState, AppState['settings']>(
		({ settings }) => settings
	);

	const handleToggleDrawer = () => {
		dispatch(toggleNavCollapsed());
	};

	const classes = useStyles({ themeStyle, themeMode });
	let sidebarClasses = classes.sidebarModern;
	if (themeStyle === ThemeStyle.STANDARD) {
		sidebarClasses = classes.sidebarStandard;
	}

	return (
		<>
			<Hidden lgUp>
				<Drawer
					anchor={position}
					open={navCollapsed}
					onClose={() => handleToggleDrawer()}
					classes={{
						paper: clsx(variant),
					}}
					style={{ position: 'absolute' }}
				>
					<Box height="100%" className={classes.drawerContainer}>
						<Box
							height="100%"
							width="100%"
							color="primary.contrastText"
							className={classes.sidebarBg}
						>
							<Scrollbar
								scrollToTop={false}
								className={classes.drawerScrollAppSidebar}
							>
								<Navigation />
							</Scrollbar>
						</Box>
					</Box>
				</Drawer>
			</Hidden>
			<Hidden mdDown>
				<Box
					height="100%"
					className={`${classes.container} ${
						window.location.pathname.includes('onboarding') && 'w-60'
					}`}
				>
					<Box
						style={{
							position: 'fixed',
							top: '56px',
							width: window.location.pathname.includes('onboarding')
								? '15rem'
								: '11.5rem',
						}}
						className={clsx(classes.sidebarBg, sidebarClasses)}
					>
						<Navigation />
					</Box>
				</Box>
			</Hidden>
		</>
	);
};

export default AppSidebar;
