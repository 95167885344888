import { useContext } from 'react';

import { APIErrorContext } from '@healthme/utility/APIErrorProvider';

/*
 **-------------------------------------------------------------------------------------
 ** METHOD NAME - useAPIError
 **-------------------------------------------------------------------------------------
 usage 
 
 import useAPIError from 'shared/hooks/useAPIError';
 const { addError } = useAPIError();
 addError('Oh No, there is an error');
 */
function useAPIError() {
	const { error, addError, removeError } = useContext(APIErrorContext);
	return { error, addError, removeError };
}

export default useAPIError;
