import React, { Fragment, useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { BASE_URL } from 'shared/constants/AppConst';
import AppContextPropsType from 'types/AppContextPropsType';

import { Dialog, Popover, Tab, Transition } from '@headlessui/react';
import patientService from '@healthme/services/patient';
import AppContext from '@healthme/utility/AppContext';
import { ShoppingBagIcon } from '@heroicons/react/24/outline';
import { makeStyles } from '@material-ui/core';

import useStyles from './AppHeader.style';

interface AppHeaderProps {}

const AppHeader: React.FC<AppHeaderProps> = () => {
	let hasRun = false;
	const [open, setOpen] = useState(false);
	const [showLanguage, setShowLanguage] = React.useState(false);

	const { imageUrl } = useContext<AppContextPropsType>(AppContext);
	const googleTranslateElementInit = () => {
		if (hasRun) return;
		hasRun = true;
		try {
			if (window['google'] && window.google.hasOwnProperty('translate')) {
				const f = window.google as any;
				new f.translate.TranslateElement(
					{
						pageLanguage: 'en',
						layout: f.translate.TranslateElement.FloatPosition.TOP_LEFT,
					},
					'google_translate_element'
				);
			}
		} catch (e) {}
	};
	React.useEffect(() => {
		var addScript = document.createElement('script');
		addScript.setAttribute(
			'src',
			'//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit'
		);
		document.body.appendChild(addScript);
		window['googleTranslateElementInit'] = googleTranslateElementInit;
	}, [window]);
	React.useEffect(() => {
		if (imageUrl)
			setLogoPath(`${BASE_URL}/${imageUrl}` || '/assets/logos/HealthMe_Logo_Color.png');
		else setLogoPath('/assets/logos/HealthMe_Logo_Color.png');
	}, []);

	const classes = useStyles();
	const { primary, cart } = React.useContext<AppContextPropsType>(AppContext);
	const useHeaderStyles = makeStyles(theme => ({
		root: {
			borderBottomColor: primary,
			borderBottom: '8px solid',
		},
	}));
	const marketplaceSlug = patientService.get('marketplaceSlug', 'marketplace');
	const orgSlug = patientService.get('orgSlug', 'org');
	const headerClasses = useHeaderStyles();
	const { gfe_only: isGFE } = patientService.getPatientOrganization();
	const [logoPath, setLogoPath] = React.useState('/assets/logos/HealthMe_Logo_Color.png');
	return (
		<>
			<Transition.Root show={open} as={Fragment}>
				<Dialog as="div" className="relative z-40 lg:hidden" onClose={setOpen}>
					<Transition.Child
						as={Fragment}
						enter="transition-opacity ease-linear duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="transition-opacity ease-linear duration-300"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<div className="fixed inset-0 bg-black bg-opacity-25" />
					</Transition.Child>
					<div className="fixed inset-0 z-40 flex">
						<Transition.Child
							as={Fragment}
							enter="transition ease-in-out duration-300 transform"
							enterFrom="-translate-x-full"
							enterTo="translate-x-0"
							leave="transition ease-in-out duration-300 transform"
							leaveFrom="translate-x-0"
							leaveTo="-translate-x-full"
						>
							<Dialog.Panel className="relative flex flex-col w-full max-w-xs pb-12 overflow-y-auto bg-white shadow-xl">
								<div className="flex px-4 pt-5 pb-2">
									<button
										type="button"
										className="inline-flex items-center justify-center p-2 -m-2 text-gray-400 rounded-md"
										onClick={() => setOpen(false)}
									>
										<span className="sr-only">Close menu</span>
									</button>
								</div>

								{/* Links */}
								<Tab.Group as="div" className="mt-2">
									<div className="border-b border-gray-200">
										<Tab.List className="flex px-4 -mb-px space-x-8"></Tab.List>
									</div>
									<Tab.Panels as={Fragment}></Tab.Panels>
								</Tab.Group>

								<div className="px-4 py-6 space-y-6 border-t border-gray-200"></div>

								<div className="px-4 py-6 space-y-6 border-t border-gray-200"></div>

								<div className="px-4 py-6 border-t border-gray-200">
									<a href="#" className="flex items-center p-2 -m-2">
										<img
											src="https://tailwindui.com/img/flags/flag-canada.svg"
											alt=""
											className="flex-shrink-0 block w-5 h-auto"
										/>
										<span className="block ml-3 text-base font-medium text-gray-900">
											US
										</span>
										<span className="sr-only">, change language</span>
									</a>
								</div>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</Dialog>
			</Transition.Root>
			<header className="relative bg-white">
				<nav aria-label="Top" className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
					<div className="border-b border-gray-200">
						<div className="flex items-center h-16">
							<div className="flex lg:ml-0">
								<Link to={`/${orgSlug}/${marketplaceSlug}`}>
									<span className="sr-only">HealthMe</span>
									<img className="w-auto h-10" src={logoPath} alt="" />
								</Link>
							</div>
							{/* Flyout menus */}
							<Popover.Group className="hidden lg:ml-8 lg:block lg:self-stretch">
								<div className="flex h-full space-x-8"></div>
							</Popover.Group>

							<div className="flex items-center ml-auto">
								<div className="flex lg:ml-8">
									<a
										href="#"
										className="flex items-center text-gray-700 hover:text-gray-800"
									>
										<img
											src="https://tailwindui.com/img/flags/flag-united-states.svg"
											alt=""
											className="flex-shrink-0 block w-5 h-auto"
											onClick={() => setShowLanguage(!showLanguage)}
										/>
										<span className="sr-only">, change language</span>
									</a>
									<div className={`ml-5 mt-0 ${showLanguage ? '' : 'hidden'}`}>
										<div>
											<div id="google_translate_element"></div>
										</div>
									</div>
								</div>
								{/* Cart */}
								<div className={`ml-4 flow-root lg:ml-6`}>
									<Link
										to={`/${orgSlug}/${marketplaceSlug}/cart`}
										className="flex items-center p-2 -m-2 group"
									>
										<ShoppingBagIcon
											className="flex-shrink-0 w-6 h-6 text-gray-400 group-hover:text-gray-500"
											aria-hidden="true"
										/>
										{/* <span className="ml-2 text-sm font-medium text-gray-700 group-hover:text-gray-800">
											{cart.length}
										</span> */}
										<span className="sr-only">items in cart, view bag</span>
									</Link>
								</div>
							</div>
						</div>
					</div>
				</nav>
			</header>
			{/* <AppBar className={headerClasses.root + ' noprint'} color="inherit" elevation={0}>
				<Toolbar className={classes.appToolbar}>
					<AppLogo usePatientLogo={true} />
					<Box className="flex justify-end w-full">
						<div className="mr-5 lt-md:hidden">
							<div>
								<div id="google_translate_element"></div>
							</div>
						</div>
						<div className="relative text-center">
							{!isGFE && (
								<>
									<Link to={`/${orgSlug}/${marketplaceSlug}/cart`}>
										<Icon
											type="Cart"
											classNames="w-10 xs:w-7 icon-blue cursor-pointer ml-2 xs:ml-3"
										/>
										<p className="text-xs">Your Cart</p>
										<div className="absolute text-2xl font-bold top-1 -left-2 secondary text-blue">
											{cart}
										</div>
									</Link>
								</>
							)}
						</div>
					</Box>
				</Toolbar>
			</AppBar> */}
		</>
	);
};
export default AppHeader;
