import { ApiCore } from '@healthme/services/api/utilities/Core';

/*
 **-------------------------------------------------------------------------------------
 ** METHOD NAME - analytics Class
 **-------------------------------------------------------------------------------------
 */
class Analytics extends ApiCore {}

/*
 **-------------------------------------------------------------------------------------
 ** METHOD NAME - analytics
 **-------------------------------------------------------------------------------------
 */
const analytics = new Analytics({
	url: 'patient_accounts',
});

export default analytics;
