import React from 'react';

interface ThemeSettingProps {}

const ThemeSetting: React.FC<ThemeSettingProps> = () => {

  return (
    <>
    </>
  );
};

export default ThemeSetting;
