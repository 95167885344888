import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Zendesk from 'react-zendesk';

import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

import { AppState } from '../../../redux/store';
import useStyles from '../../../shared/jss/common/common.style';
import AppContextPropsType from '../../../types/AppContextPropsType';
import { ContentView } from '../../index';
import AppContext from '../../utility/AppContext';
import Layouts from './Layouts';

const ZENDESK_KEY = '76665917-7b35-4444-add9-af8ae6539a5e';
const setting = {
	color: {
		theme: '#000',
	},
	launcher: {
		chatLabel: {
			'en-US': 'Need Help',
		},
	},
	contactForm: {
		fields: [{ id: 'description', prefill: { '*': 'My pre-filled description' } }],
	},
};
const useStyle = makeStyles(() => ({
	appAuth: {
		position: 'relative',
		height: '100vh',
		backgroundColor: '#f3f4f6',
		backgroundSize: 'cover',

		'& .scrollbar-container': {
			flex: 1,
			display: 'flex',
			flexDirection: 'column',
		},
		'& .main-content-view': {
			padding: 20,
			backgroundColor: '#EDF4FD',
		},
		'& .footer': {
			marginRight: 0,
			marginLeft: 0,
		},
	},
}));

interface HealthMeLayoutProps {}

const HealthMeLayout: React.FC<HealthMeLayoutProps> = () => {
	useStyles();
	const location = useLocation();

	const { navStyle } = useContext<AppContextPropsType>(AppContext);
	const { user } = useSelector<AppState, AppState['auth']>(({ auth }) => auth);

	const AppLayout = Layouts[navStyle];

	const AppPatientLayoutv2 = Layouts.patientv2;
	const AppBlankLayout = Layouts.blank;
	const AppNetworkLayout = Layouts.network;
	const patientRoutes = ['auth', 'secure', 'error-pages'];
	const networkSlug = window.location.pathname.split('/')[1];
	const blankPageRoutes = ['/payment', '/invoice-order-complete', '/invoice'];
	const classes = useStyle();
	const matches = patientRoutes.filter(s => window.location.pathname.includes(s));

	if (blankPageRoutes.find(e => e === location.pathname)) {
		return (
			<>
				<AppBlankLayout />
			</>
		);
	}

	if (networkSlug === 'n' || window.location.hostname.includes('network.'))
		return (
			<>
				<AppNetworkLayout />
				{/*<Zendesk
					defer
					zendeskKey={ZENDESK_KEY}
					{...setting}
					onLoaded={() => console.log('is loaded')}
				/> */}
			</>
		);

	if (!matches.length)
		return (
			<>
				<AppPatientLayoutv2 />
			</>
		);
	return (
		<>
			{user ? (
				<>
					<AppLayout />
					<Zendesk defer zendeskKey={ZENDESK_KEY} {...setting} />
				</>
			) : (
				<Box className={classes.appAuth}>
					<ContentView />
				</Box>
			)}
		</>
	);
};

export default React.memo(HealthMeLayout);
