import routes from 'modules';
import React from 'react';
import { renderRoutes } from 'react-router-config';
import AppContextPropsType from 'types/AppContextPropsType';

import AppErrorBoundary from '@healthme/core/AppErrorBoundary';
import AppSuspense from '@healthme/core/AppSuspense';
import patientService from '@healthme/services/patient';
import AppContext from '@healthme/utility/AppContext';

import AppFooter from './AppFooter';
import AppHeader from './AppHeader';
import Initializer from './Initializer';

interface DefaultLayoutProps {}

const DefaultLayout: React.FC<DefaultLayoutProps> = () => {
	const { loading, primary, imageUrl } = React.useContext<AppContextPropsType>(AppContext);
	const marketplaceSlug = patientService.get('marketplaceSlug', 'marketplace');
	const orgSlug = localStorage.getItem('orgSlug');

	return (
		<>
			<AppSuspense>
				<Initializer />
				{!loading && (
					<>
						<AppHeader
							primary={primary}
							imageUrl={imageUrl}
							baseUrl={`/${orgSlug}/${marketplaceSlug}`}
						/>
						<div className="">
							<AppErrorBoundary>{renderRoutes(routes)}</AppErrorBoundary>
						</div>
						<AppFooter />
					</>
				)}
			</AppSuspense>
		</>
	);
};
export default DefaultLayout;
