import React from 'react';
import { Typeahead } from 'react-bootstrap-typeahead'; // ES2015

import Icon from '@healthme/core/Icon/Index';

interface Props {
	list: any[];
	title: string;
	onChange: (val: string) => void;
	error?: any;
	value?: any;
	name?: string;
	multiple?: boolean;
	className?: string;
	disabled?: boolean;
	clear?: boolean | number;
	positionFixed?: Boolean;
	dropup?: Boolean;
	useNormal?: Boolean;
	alterSelectedLabel?: (item) => string;
}

const SelectField: React.FC<Props> = props => {
	const [open, setOpen] = React.useState(false);
	const [selectedValue, setSelectedValue] = React.useState(props.value);
	const [selected, setSelected] = React.useState<any>([]);
	const ref = React.useRef();
	React.useEffect(() => {
		if (Array.isArray(props.value)) {
			const values = props.list.filter(item => props.value.includes(item.value));
			setSelected(values);
			setSelectedValue(props.value);
			return;
		}
		const value = props.list.find(item => item.value === props.value);
		if (value) {
			if (props.useNormal) {
				setSelected(value.label);
				setSelectedValue(value.label);
				return;
			} else {
				setSelected([value]);
				setSelectedValue([value]);
			}
		}
		if (!props.value || !props.value.length) setSelectedValue('');
	}, [props.value]);
	React.useEffect(() => {
		if (props.clear) {
			setSelected([]);
			setSelectedValue('');
		}
	}, [props.clear]);

	// React.useEffect(() => {
	// 	console.log(props.value);
	// 	const value = props.list.find(item => item.value === props.value);
	// 	if (value) {
	// 		setSelected([value]);
	// 		setSelectedValue([value]);
	// 	}
	// 	if (!props.value || !props.value.length) setSelectedValue('');
	// }, [props.value, props.list]);
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - handleInputClick
	 **-------------------------------------------------------------------------------------
	 */
	const handleInputClick = () => {
		if (selectedValue && !selectedValue.length) setSelectedValue('');
		setOpen(!open);
	};
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - selectValue
	 **-------------------------------------------------------------------------------------
	 */
	const selectValue = (item: any) => {
		if (!item) {
			item = { value: '' };
		}
		if (props.multiple) {
			const values = item.map(item => item.value);
			const labels = item.map(item => item.label);
			props.onChange(values);
			setSelectedValue(values);
			setOpen(!open);
			return;
		}
		props.onChange(item.value);
		setSelectedValue(item.label);
		setOpen(!open);
	};

	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - closeDropDown
	 **-------------------------------------------------------------------------------------
	 */
	const closeDropDown = () => {
		if (selectedValue && !selectedValue.length) setSelectedValue('');
		setOpen(false);
	};
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - Return
	 **-------------------------------------------------------------------------------------
	 */
	return (
		<>
			{!props?.useNormal && (
				<>
					<div className="relative mb-0 floating-label" onMouseLeave={closeDropDown}>
						<div
							className={`floating-label select-container w-full mb-0 z-90 ${props.className}`}
						>
							<Typeahead
								filterBy={(option, props) => {
									if (props.selected.length) {
										return true;
									}
									return (
										option.label
											.toLowerCase()
											.indexOf(props.text.toLowerCase()) !== -1
									);
								}}
								id={`__input_${props?.name}`}
								className={`normal-typeahead __input_${props?.name} ${
									props?.error?.length && 'error'
								}`}
								options={props.list}
								onChange={selected => {
									setSelected(selected);
									if (selected.length && !props.multiple)
										selectValue(selected[0]);
									if (selected.length && props.multiple) selectValue(selected);
									if (!selected.length && props.multiple) selectValue([]);
									if (!selected.length && !props.multiple)
										selectValue({ label: null, value: null });
								}}
								placeholder={props.title}
								selected={selected}
								ref={ref}
								onFocus={e => {
									setOpen(true);
								}}
								onBlur={e => {
									setOpen(false);
								}}
								onMenuToggle={setOpen}
								multiple={props.multiple}
								inputProps={{ autoComplete: 'noop' }}
								disabled={props.disabled}
								clearButton
								dropup={props.dropup ? true : false}
								positionFixed={props.positionFixed ? true : false}
								labelKey="label"
								renderMenuItemChildren={option => {
									let isSelected = false;
									if (selected.length && option.value === selected[0].value)
										isSelected = true;
									return (
										<div
											key={option.value}
											className={`select-option-type  ${
												isSelected ? 'selected' : 'not-selected'
											}`}
										>
											<div className="flex items-center justify-between">
												<span className="inline-block">
													{option.label}{' '}
												</span>
												<span className="inline-block -ml-5 -indent-5">
													{option?._detail}
												</span>
											</div>
											{isSelected && (
												<Icon
													type="Checkmark"
													classNames="selected-checkmark icon-white w-4"
												/>
											)}
										</div>
									);
								}}
								useCache={false}
							/>
							<Icon
								type="DownArrow"
								classNames={`select-down-arrow w-4 ${!open ? '' : 'active'}`}
							/>
							{props.error && <p className="error-msg -bottom-1">{props?.error}</p>}
						</div>
					</div>
				</>
			)}
			{props?.useNormal && (
				<div
					className={`relative floating-label ${props.className}`}
					onMouseLeave={closeDropDown}
				>
					<div className="w-full floating-label select-container">
						<input
							className={`floating-input select-dropdown ${
								props?.error?.length && 'error'
							}`}
							type="text"
							placeholder=" "
							readOnly
							value={selectedValue}
							onClick={handleInputClick}
							name={props?.name ?? 'none'}
							id={props?.name ?? 'none'}
							onChange={(e: any) => {
								props.onChange(e);
							}}
						/>

						<label>{props.title}</label>
						<Icon
							type="DownArrow"
							classNames={`select-down-arrow w-4 ${!open ? '' : 'active'}`}
						/>
						{props.error && <p className="error-msg">{props?.error}</p>}
					</div>
					{open && (
						<div
							className={`w-full floating-select-dropdown max-h-60 overflow-y-auto`}
							onMouseLeave={handleInputClick}
						>
							{props.list.map((item, idx) => {
								return (
									<div
										onClick={() => selectValue(item)}
										key={idx}
										className={`select-option ease-linear transition-all duration-150 ${
											item.value === selectedValue
												? 'selected'
												: 'not-selected'
										}`}
									>
										{item.label}
										{item.value === selectedValue && (
											<Icon
												type="Checkmark"
												classNames="selected-checkmark icon-white w-4"
											/>
										)}
									</div>
								);
							})}
						</div>
					)}
				</div>
			)}
		</>
	);
};

export default SelectField;
