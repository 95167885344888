import React, { useCallback, useState } from 'react';

export const APIErrorContext = React.createContext({
	error: null,
	addError: (error: any) => {},
	removeError: () => {},
});

export default function APIErrorProvider({ children }: any) {
	const [error, setError] = useState({});

	const removeError = () => setError({});

	const addError = (message: any, status: any) => setError({ message, status });

	const contextValue: any = {
		error,
		addError: useCallback((message, status) => addError(message, status), []),
		removeError: useCallback(() => removeError(), []),
	};

	return <APIErrorContext.Provider value={contextValue}>{children}</APIErrorContext.Provider>;
}
