export default {
	get,
	post,
	put,
	delete: _delete,
	patch,
	postFile,
	getCSV,
};

async function get(url, headers = {}) {
	const requestOptions: RequestInit = {
		method: 'GET',
		headers,
		credentials: 'include',
	};
	const resp = await fetch(`${url}`, requestOptions);
	await handleErrorResposne(resp);
	const returnedData = await resp.json();
	return { data: returnedData, resp };
}

async function getCSV(url, headers = {}) {
	const requestOptions: RequestInit = {
		method: 'GET',
		headers,
		credentials: 'include',
	};
	const resp = await fetch(`${url}`, requestOptions);
	await handleErrorResposne(resp);
	const returnedData = await resp.text();
	return { data: returnedData, resp };
}

async function post(url, body, headers = {}) {
	const requestOptions: RequestInit = {
		method: 'POST',
		body: JSON.stringify(body),
		headers,
		credentials: 'include',
	};
	const resp = await fetch(`${url}`, requestOptions);
	await handleErrorResposne(resp, body);
	if (resp.status === 202 && url.includes('staff_accounts/login')) {
		return { mfa: true };
	}
	const returnedData = await resp.json();
	return { data: returnedData, resp };
}

async function postFile(url, body, headers = {}) {
	const requestOptions: RequestInit = {
		method: 'POST',
		body,
		headers: {
			...headers,
		},
		credentials: 'include',
	};
	const resp = await fetch(`${url}`, requestOptions);
	await handleErrorResposne(resp);
	try {
		const returnedData = await resp.json();
		return { data: returnedData, resp };
	} catch (e) {
		return { data: [], resp };
	}
}

async function put(url, body, headers = {}) {
	const requestOptions: RequestInit = {
		method: 'PUT',
		body: JSON.stringify(body),
		headers,
		credentials: 'include',
	};
	const resp = await fetch(`${url}`, requestOptions);
	await handleErrorResposne(resp);
	const returnedData = await resp.json();
	return { data: returnedData, resp };
}

async function patch(url, body, headers = {}) {
	const requestOptions: RequestInit = {
		method: 'PATCH',
		headers,
		body: JSON.stringify(body),
		credentials: 'include',
	};
	const resp = await fetch(`${url}`, requestOptions);
	await handleErrorResposne(resp);
	const returnedData = await resp.json();
	return { data: returnedData, resp };
}

async function _delete(url, headers = {}) {
	const requestOptions: RequestInit = {
		headers,
		method: 'DELETE',
		credentials: 'include',
	};
	const resp = await fetch(`${url}`, requestOptions);
	await handleErrorResposne(resp);
	const returnedData = await resp.json();
	return { data: returnedData, resp };
}

async function handleErrorResposne(response, body = null) {
	const isLoggedIn = localStorage.getItem('loggedIn');
	if (isLoggedIn && !response.ok && response.status === 401) {
		console.error('Unauthorized');
		localStorage.clear();
		deleteAllCookies();
		window.location.href = '/auth/signin';
		return;
	}

	if (!response.ok) {
		const errors = await response.json();

		if (errors?.error === 'no_user_password') {
			console.log(body);
			window.location.href = `/auth/forget-password?email=${encodeURIComponent(
				body.username
			)}`;
			return;
		}

		if (errors?.detail && typeof errors.detail === 'string') {
			throw {
				data: errors.detail,
			};
		}
		const errorString = {
			[Array.isArray(errors?.errors) ? 'errors' : 'error']: Array.isArray(errors?.errors)
				? [...errors?.errors]
				: errors.error,
		};
		throw {
			data: JSON.stringify(errorString),
		};
	}
	return response;
}
function deleteAllCookies() {
	const cookies = document.cookie.split(';');

	for (let i = 0; i < cookies.length; i++) {
		const cookie = cookies[i];
		const eqPos = cookie.indexOf('=');
		const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
		document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
	}
}
