import { networkRouteConfig } from 'modules/network/Index';
import React from 'react';
import { useQuery } from 'react-query';
import { renderRoutes } from 'react-router-config';
import { useParams } from 'react-router-dom';
import { BASE_URL } from 'shared/constants/AppConst';

import { AppSuspense, Loader } from '@healthme';
import AppErrorBoundary from '@healthme/core/AppErrorBoundary';
import { cacheApi2, createRoutes, getNetworkSlug } from '@healthme/utility/Utils';

import AppFooter from '../AppFooter';
import AppHeader from './AppHeader';

interface DefaultLayoutProps {}

const routes = [...createRoutes(networkRouteConfig)];

const DefaultLayout: React.FC<DefaultLayoutProps> = () => {
	const params: any = useParams();
	const networkSlug = getNetworkSlug();
	console.log(networkSlug);
	const { data, isLoading } = useQuery(
		`networks:${params.networkSlug}`,
		() => {
			return cacheApi2(`public/network_organizations/${networkSlug}`, {});
		},
		{
			onSuccess: resp => {
				localStorage.setItem('networkOrganizationId', resp.id);
				localStorage.setItem('publicNetworkOrg', JSON.stringify(resp));
			},
			refetchOnWindowFocus: false,
		}
	);
	if (isLoading) return <Loader />;
	return (
		<>
			<AppSuspense>
				<AppHeader
					primary={data.primary_color}
					baseUrl={`/n/${networkSlug}`}
					imageUrl={`${BASE_URL}/${data?.image_path}`}
				/>
				<div className="">
					<AppErrorBoundary>{renderRoutes(routes)}</AppErrorBoundary>
				</div>
				<AppFooter />
			</AppSuspense>
		</>
	);
};

export default DefaultLayout;
