import React from 'react';
import useAPIError from 'shared/hooks/useAPIError';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			width: '80%',
			margin: '1rem auto',
			'& > * + *': {
				marginTop: theme.spacing(2),
			},
		},
	})
);
export default function APIErrorNotification() {
	const { error, removeError } = useAPIError();
	const classes = useStyles();
	const displayError: any = error;
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - handleClose
	 **-------------------------------------------------------------------------------------
	 */
	const handleClose = () => {
		removeError();
	};
	return (
		<>
			{displayError?.message ? (
				<div className={classes.root}>
					<Alert onClose={handleClose} severity="error">
						{displayError.message}
					</Alert>
				</div>
			) : null}
		</>
	);
}
