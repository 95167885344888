import clsx from 'clsx';
import React, { useContext } from 'react';
import APIErrorNotification from 'shared/components/APIErrorNotification';

import { ErrorMessage } from '@healthme/core/ErrorMessage/Index';
import Box from '@material-ui/core/Box';
import Hidden from '@material-ui/core/Hidden';

import { LayoutType } from '../../../../shared/constants/AppEnums';
import AppContextPropsType from '../../../../types/AppContextPropsType';
import { ContentView } from '../../../index';
import AppContext from '../../../utility/AppContext';
import AppFixedFooter from './AppFixedFooter';
import AppHeader from './AppHeader';
import AppSidebar from './AppSidebar';
import useStyles from './index.style';

interface DefaultLayoutProps {}

const DefaultLayout: React.FC<DefaultLayoutProps> = () => {
	const { themeStyle, footer, layoutType, footerType } =
		useContext<AppContextPropsType>(AppContext);
	const classes = useStyles({ themeStyle, footer });
	return (
		<Box
			className={clsx(
				classes.appMain,
				layoutType === LayoutType.BOXED ? classes.boxedLayout : '',
				{
					appMainFooter: footer && footerType === 'fluid',
					appMainFixedFooter: footer && footerType === 'fixed',
				}
			)}
		>
			<AppHeader />
			<Box
				style={{
					width: window.location.pathname.includes('onboarding')
						? 'calc(100vw - 15rem)'
						: 'inherit',
				}}
				className={`${classes.mainContent}`}
			>
				<AppSidebar />

				<Hidden mdDown>
					<Box className={classes.mainContainer + ' relative'}>
						<APIErrorNotification />
						<ContentView />
					</Box>
				</Hidden>

				<Hidden lgUp>
					<Box className={classes.mainContainerFull + ''}>
						<ContentView />
					</Box>
				</Hidden>
			</Box>
			<div className="hidden w-full p-4">
				<ErrorMessage message="This portal is optimized resolution >= 1280x1024" />
			</div>
			<AppFixedFooter />
		</Box>
	);
};

export default DefaultLayout;
