import React from 'react';

import Button, { ButtonType } from '@healthme/core/Button/Index';
import ErrorHandler from '@healthme/core/ErrorHandler/Index';
import Dialog from '@material-ui/core/Dialog';

import Icon from '../Icon/Index';
import Loader from '../Loader';

export interface Props {
	open: boolean;
	onClose: (value: boolean) => void;
	children?: React.ReactNode;
	cancelBtn?: string;
	confirmBtn?: string;
	title?: string;
	maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
	width?: string;
	confirmDialog?: boolean;
	confirmBtnType?: ButtonType;
	classNames?: string;
	showClose?: boolean;
	padding?: string;
	overflow?: string;
	isLoading?: boolean;
}

const Modal: React.FC<Props> = ({
	padding = 'py-6 px-4',
	confirmBtnType = 'primary',
	overflow = 'overflow-auto',
	isLoading = false,
	...props
}) => {
	const handleCancelClose = () => {
		props.onClose(false);
	};
	const handleConfirmClose = () => {
		props.onClose(true);
	};
	const classes = props?.confirmDialog ? 'mr-6 mt-12' : 'w-9/12 max-w-full mt-12';
	return (
		<Dialog
			disableBackdropClick
			disableEscapeKeyDown
			fullWidth={true}
			maxWidth={props.maxWidth}
			onClose={handleCancelClose}
			open={props.open}
			style={{ overflow: 'visible', borderRadius: '9999px !important' }}
			className="rounded-lg"
		>
			{isLoading && <Loader />}
			{props.showClose && (
				<a onClick={handleCancelClose}>
					<Icon
						type="Close"
						classNames="absolute top-5 right-5 w-3.5 icon-gray opacity-60 cursor-pointer"
					/>
				</a>
			)}
			<div
				className={`${padding} ${overflow} ${props.maxWidth} ${props.classNames}`}
				style={{ width: props.width }}
			>
				{props.title && (
					<div className="mb-4 text-lg font-semibold text-center">{props.title}</div>
				)}
				<ErrorHandler />
				<div className="">{props.children}</div>
				<div className="flex items-center justify-end w-full my-2.5">
					{props.cancelBtn && (
						<Button onClick={handleCancelClose} type="text-link" color="text-blue">
							{props.cancelBtn}
						</Button>
					)}
					{props.confirmBtn && (
						<Button type={confirmBtnType} onClick={handleConfirmClose}>
							{props.confirmBtn}
						</Button>
					)}
				</div>
			</div>
		</Dialog>
	);
};
Modal.defaultProps = {
	confirmBtn: '',
	maxWidth: 'md',
	showClose: true,
};
export default Modal;
