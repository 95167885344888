import './LanguageSelector.css'; // Import the CSS file

import React from 'react';

let hasRun = false;

export default function LanguageSelector() {
	const [showLanguage, setShowLanguage] = React.useState(false);

	const googleTranslateElementInit = () => {
		if (hasRun) return;
		hasRun = true;
		try {
			if (window['google'] && window.google.hasOwnProperty('translate')) {
				const f = window.google as any;
				new f.translate.TranslateElement(
					{
						pageLanguage: 'en',
						layout: f.translate.TranslateElement.FloatPosition.TOP_LEFT,
					},
					'google_translate_element'
				);
				// Add event listener for language change
				const observer = new MutationObserver(() => {
					const selectElement: any = document.querySelector('.goog-te-combo');
					if (selectElement) {
						selectElement.classList.add('custom-select'); // Add custom class
						selectElement.addEventListener('change', () => {
							console.log('Language changed to:', selectElement.value);
							setShowLanguage(false);
						});
						observer.disconnect();
					}
				});
				observer.observe(document.body, { childList: true, subtree: true });
			}
		} catch (e) {}
	};

	React.useEffect(() => {
		var addScript = document.createElement('script');
		addScript.setAttribute(
			'src',
			'//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit'
		);
		document.body.appendChild(addScript);
		window['googleTranslateElementInit'] = googleTranslateElementInit;
	}, [window]);

	return (
		<>
			<div className="flex items-center px-4 py-6 border-t border-gray-200">
				<a href="#" className="flex items-center p-2 -m-2">
					<img
						src="/assets/icons/usa-flag.svg"
						alt=""
						className="flex-shrink-0 block w-8 h-auto"
						onClick={() => setShowLanguage(!showLanguage)}
					/>
					<span className="sr-only">, change language</span>
				</a>
				<div className={`ml-5 mt-0 ${showLanguage ? '' : 'hidden'}`}>
					<div>
						<div id="google_translate_element"></div>
					</div>
				</div>
			</div>
		</>
	);
}
