// listener.js
import { useEffect, useState } from 'react';

import { errorParser } from '@healthme/utility/Utils';
import Alert from '@material-ui/lab/Alert';

const ErrorHandler = () => {
	const [errors, setErrors] = useState<string[]>([]);

	const Errors = () => {
		return (
			<ul className="ml-5">
				{errors.map((error, index) => (
					<li className="list-disc" key={index}>
						{error}
					</li>
				))}
			</ul>
		);
	};

	useEffect(() => {
		const handleCustomEvent = event => {
			console.log('Received Error event:', errorParser(event.detail));
			setErrors([errorParser(event.detail)]);
		};
		window.addEventListener('error', handleCustomEvent);
		return () => {
			setErrors([]);
			window.removeEventListener('error', handleCustomEvent);
		};
	}, []);

	return <>{errors.length > 0 ? <Alert severity="error">{Errors()}</Alert> : null}</>;
};

export default ErrorHandler;
