import { ApiCore } from '@healthme/services/api/utilities/Core';

/*
 **-------------------------------------------------------------------------------------
 ** METHOD NAME - Organization Class
 **-------------------------------------------------------------------------------------
 */
class Organization extends ApiCore {}

/*
 **-------------------------------------------------------------------------------------
 ** METHOD NAME - organiza
 **-------------------------------------------------------------------------------------
 */
const organizations = new Organization({
	url: 'organizations',
});

export default organizations;
