const pathArr = window.location.pathname.split('/');
const orgSlug = window.location.pathname.split('/')[1];

class User {
	marketplace = window.location.pathname.split('/');
	/*
	 **-------------------------------------------------------------------------------------
	 ** FN NAME - getPath
	 **-------------------------------------------------------------------------------------
	 */
	getMarketplacePath() {
		const orgSlug = localStorage.getItem('orgSlug');
		const marketplaceSlug = localStorage.getItem('marketplaceSlug');
		return `${orgSlug}/${marketplaceSlug}`;
	}
	getOrgSlug = localStorage.getItem('orgSlug');
	getMarketplaceSlug = localStorage.getItem('marketplaceSlug');
	/*
	 **-------------------------------------------------------------------------------------
	 ** FN NAME - getPatientOrganization
	 **-------------------------------------------------------------------------------------
	 */
	getPatientOrganization() {
		return JSON.parse(localStorage.getItem(`${orgSlug}-patientOrganization`) || '{}');
	}
	/*
	 **-------------------------------------------------------------------------------------
	 ** FN NAME - getPatientOrganization
	 **-------------------------------------------------------------------------------------
	 */
	setPatientOrganization(data) {
		if (!orgSlug) return;

		localStorage.setItem(`${orgSlug}-orgSlug`, data.name_slug);
		localStorage.setItem(`${orgSlug}-patientOrganization`, JSON.stringify(data));
	}
	/*
	 **-------------------------------------------------------------------------------------
	 ** FN NAME - getPatientMarketplace
	 **-------------------------------------------------------------------------------------
	 */
	getPatientMarketplace() {
		if (!this.marketplace) return;

		if (localStorage.getItem(`${this.marketplace}-patientMarketplace`)) {
			return JSON.parse(localStorage.getItem(`${this.marketplace}-patientMarketplace`) || '');
		}
		return null;
	}
	/*
	 **-------------------------------------------------------------------------------------
	 ** FN NAME - setPatientMarketplace
	 **-------------------------------------------------------------------------------------
	 */
	setPatientMarketplace(data) {
		if (!this.marketplace) this.marketplace = data.name_slug;

		localStorage.setItem(`${this.marketplace}-marketplaceSlug`, data.name_slug);
		localStorage.setItem(`${this.marketplace}-patientMarketplace`, JSON.stringify(data));
	}
	/*
	 **-------------------------------------------------------------------------------------
	 ** FN NAME - get
	 **-------------------------------------------------------------------------------------
	 */
	get(key, type) {
		if (type === 'org') return localStorage.getItem(`${orgSlug}-${key}`) || '';
		return localStorage.getItem(`${this.marketplace}-${key}`) || '';
	}
	/*
	 **-------------------------------------------------------------------------------------
	 ** FN NAME - get
	 **-------------------------------------------------------------------------------------
	 */
	set(key, value, type) {
		if (type === 'org') return localStorage.setItem(`${orgSlug}-${key}`, value);
		return localStorage.setItem(`${this.marketplace}-${key}`, value);
	}
	/*
	 **-------------------------------------------------------------------------------------
	 ** FN NAME - get
	 **-------------------------------------------------------------------------------------
	 */
	delete(key, type) {
		if (type === 'org') return localStorage.removeItem(`${orgSlug}-${key}`);
		return localStorage.removeItem(`${this.marketplace}-${key}`);
	}
	/*
	 **-------------------------------------------------------------------------------------
	 ** FN NAME - clear
	 **-------------------------------------------------------------------------------------
	 */
	clear() {
		localStorage.removeItem(`${orgSlug}-patientOrganization`);
		localStorage.removeItem(`${orgSlug}-orgSlug`);
		localStorage.removeItem(`${this.marketplace}-patientMarketplace`);
		localStorage.removeItem(`${this.marketplace}-marketplaceSlug`);
	}
}

export default new User();
