import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { renderRoutes } from 'react-router-config';
import { useLocation } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import ScrollToTop from '@healthme/utility/ScrollTop';
import Box from '@material-ui/core/Box';

import routes from '../../../modules';
import { AppState } from '../../../redux/store';
import { RouteTransition } from '../../../shared/constants/AppEnums';
import AppContextPropsType from '../../../types/AppContextPropsType';
import { AppSuspense } from '../../index';
import AppContext from '../../utility/AppContext';
import AppErrorBoundary from '../AppErrorBoundary';
import AppFooter from '../AppLayout/AppFooter';
import Scrollbar from '../Scrollbar';

interface TransitionWrapperProps {
	children: any;
}

const TransitionWrapper: React.FC<TransitionWrapperProps> = ({ children }) => {
	const { rtAnim } = useContext<AppContextPropsType>(AppContext);

	const location = useLocation();
	if (rtAnim === RouteTransition.NONE) {
		return <>{children}</>;
	}
	return (
		<TransitionGroup appear enter exit>
			<CSSTransition
				key={location.key}
				timeout={{ enter: 300, exit: 300 }}
				classNames={rtAnim}
			>
				{children}
			</CSSTransition>
		</TransitionGroup>
	);
};

const ContentView = () => {
	const { user } = useSelector<AppState, AppState['auth']>(({ auth }) => auth);
	const scrollTo = ref => {
		if (ref /* + other conditions */) {
			ref.scrollIntoView({ behavior: 'smooth', block: 'start' });
		}
	};

	return (
		<>
			{!user && (
				<div className="w-full bg-white border-b border-gray-300 h-20 pt-5 pl-5">
					<img
						src="/assets/logos/HealthMe_Logo_Color.png"
						alt="healthme-logo"
						className="w-60 h-auto"
					/>
				</div>
			)}
			<div ref={scrollTo}>
				<Box
					display="flex"
					flex={1}
					flexDirection="column"
					className="main-content-view"
					style={{ paddingBottom: '50px' }}
				>
					<AppSuspense>
						<AppErrorBoundary>
							<TransitionWrapper>{renderRoutes(routes)}</TransitionWrapper>
						</AppErrorBoundary>
					</AppSuspense>
				</Box>
			</div>
			<AppFooter />
		</>
	);
};

export default ContentView;
