import React from 'react';

import Button from '@healthme/core/Button/Index';
import Icon from '@healthme/core/Icon/Index';
import { PatientLookupModal } from '@healthme/core/PatientLookupModal/Index';
import { MenuItem } from '@material-ui/core';

export default function PatientLookUpDialog() {
	const [openModal, setOpenModal] = React.useState(false);
	//FNS
	/*
	 **-------------------------------------------------------------------------------------
	 ** FN NAME - handleOpenClaimModal
	 **-------------------------------------------------------------------------------------
	 */
	const handleOpenClaimModal = () => {
		setOpenModal(true);
	};

	return (
		<>
			<Button
				type="secondary"
				width="medium"
				extraClasses="mr-4 lt-md:hidden"
				onClick={handleOpenClaimModal}
			>
				<Icon type="Search" classNames="w-4 inline-block icon-white mr-2 lt-md:hidden" />{' '}
				Patient Lookup
			</Button>
			<MenuItem className="hidden lt-md:block" onClick={handleOpenClaimModal}>
				Patient Lookup
			</MenuItem>

			<PatientLookupModal
				openModal={openModal}
				onClose={() => {
					setOpenModal(false);
				}}
			/>
		</>
	);
}
