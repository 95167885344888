import axios from 'axios';
import React from 'react';
import { useQueryClient } from 'react-query';
import { BASE_URL } from 'shared/constants/AppConst';

import Button from '@healthme/core/Button/Index';
import AsyncSelectField from '@healthme/core/Form/SelectField/Asnyc';
import SelectField from '@healthme/core/Form/SelectField/Index';
import Icon from '@healthme/core/Icon/Index';
import Modal from '@healthme/core/Modal/Index';
import userService from '@healthme/services/user';
import { MenuItem } from '@material-ui/core';

const STAFF_LIST = [
	{ label: 'HealthMe Admin', value: 'admin' },
	{ label: 'Organization Manager', value: 'organization_manager' },
	{ label: 'Marketplace Manager', value: 'marketplace_manager' },
	{ label: 'Network Manager', value: 'network_manager' },
	{ label: 'Staff', value: 'staff' },
	{ label: 'Basic Contributor', value: 'limited_staff' },
];
const user: any = userService.getUser();
const appAxios = axios.create({
	baseURL: BASE_URL,
	withCredentials: true,
});

export default function OrganizationChangeDialog() {
	const [openModal, setOpenModal] = React.useState(false);
	const queryClient = useQueryClient();
	const [orgData, setOrgData] = React.useState<any>(
		JSON.parse(localStorage.getItem('orgData') || '{}')
	);
	const [networkOrgData, setNetworkOrgData] = React.useState<any>(
		JSON.parse(localStorage.getItem('networkOrgData') || '{}')
	);

	const [staff, setStaff] = React.useState(localStorage.getItem('role') || 'admin');
	const [hasSelected, setHasSelected] = React.useState(
		localStorage.getItem('organization')?.length ||
			localStorage.getItem('networkOrg')?.length ||
			false
	);
	const [loggedInUser, setLoggedInUser] = React.useState(user);
	const [netwrokOrganization, setNetwrokOrganization] = React.useState(
		localStorage.getItem('networkOrg') || ''
	);

	const [organization, setOrganization] = React.useState(
		localStorage.getItem('organization') || ''
	);
	const [organizationName, setOrganizationName] = React.useState(
		localStorage.getItem('organizationName') || ''
	);

	//FNS
	/*
	 **-------------------------------------------------------------------------------------
	 ** FN NAME - handleOpenModal
	 **-------------------------------------------------------------------------------------
	 */
	const handleOpenModal = () => {
		setOpenModal(true);
	};

	/*
	 **-------------------------------------------------------------------------------------
	 ** FN NAME - handleModalClose
	 **-------------------------------------------------------------------------------------
	 */
	const handleModalClose = () => {
		setOpenModal(false);
	};
	/*
	 **-------------------------------------------------------------------------------------
	 ** FN NAME - clearAssume
	 **-------------------------------------------------------------------------------------
	 */
	const clearAssume = () => {
		localStorage.removeItem('organization');
		localStorage.removeItem('organizationName');
		localStorage.removeItem('isAssumedOrganization');
		localStorage.removeItem('isAssumedNetwork');
		localStorage.removeItem('assumeRole');
		localStorage.removeItem('networkOrg');
		localStorage.setItem('role', 'admin');
		appAxios.get(`staff_accounts/me`).then(res => {
			const u = btoa(
				unescape(encodeURIComponent(JSON.stringify({ ...res.data, isPatient: false })))
			);
			localStorage.setItem('user', u);
			localStorage.setItem('raw-user', JSON.stringify(res.data));
			queryClient.invalidateQueries('staff:me');

			window.location.href = '/secure/dashboard';
		});
	};
	/*
	 **-------------------------------------------------------------------------------------
	 ** FN NAME - setUser
	 **-------------------------------------------------------------------------------------
	 */
	const setUser = data => {
		const u = btoa(unescape(encodeURIComponent(JSON.stringify({ ...data, isPatient: false }))));
		localStorage.setItem('user', u);
		return;
	};

	/*
	 **-------------------------------------------------------------------------------------
	 ** FN NAME - handleOnClaimSelection
	 **-------------------------------------------------------------------------------------
	 */
	const handleOnClaimSelection = () => {
		queryClient.invalidateQueries('staff:me');
		if (netwrokOrganization.length > 0) {
			localStorage.setItem('networkOrg', netwrokOrganization);
			localStorage.setItem('organization', '');
			localStorage.setItem('organizationName', networkOrgData.name);
			localStorage.setItem('isAssumedOrganization', 'yes');
			localStorage.setItem('isAssumedNetwork', 'yes');
			localStorage.setItem('assumeRole', 'network_manager');
			localStorage.setItem('role', 'network_manager');
			localStorage.setItem('networkOrgData', JSON.stringify(networkOrgData));

			setUser({
				...loggedInUser,
				network_organization_id: netwrokOrganization,
				network_organization: networkOrgData,
				role: 'network_manager',
			});
			setOpenModal(false);
			window.location.href = '/secure/networks/dashboard';
			return;
		}
		setOrganizationName(orgData.name);
		localStorage.setItem('isAssumedOrganization', 'yes');
		localStorage.setItem('organization', organization);
		localStorage.setItem('organizationName', orgData.name);
		localStorage.setItem('assumeRole', staff);
		localStorage.setItem('role', staff);
		localStorage.setItem('orgData', JSON.stringify(orgData));
		const user = userService.getUser();

		setUser({
			...user,
			role: staff,
		});
		setOpenModal(false);
		window.location.href = '/secure/dashboard';
	};
	/*
	 **-------------------------------------------------------------------------------------
	 ** FN NAME - hanldeOnClaimSelectionV2
	 **-------------------------------------------------------------------------------------
	 */
	const handleOnClaimSelectionV2 = () => {
		localStorage.setItem('networkOrg', netwrokOrganization);
		localStorage.setItem('organization', '');
		localStorage.setItem('organizationName', networkOrgData.name);
		localStorage.setItem('isAssumedOrganization', 'yes');
		localStorage.setItem('isAssumedNetwork', 'yes');
		localStorage.setItem('assumeRole', 'network_manager');
		localStorage.setItem('role', 'network_manager');
		localStorage.setItem('networkOrgData', JSON.stringify(networkOrgData));

		setUser({
			...loggedInUser,
			network_organization_id: netwrokOrganization,
			network_organization: networkOrgData,
			role: 'network_manager',
		});
		setOpenModal(false);
		window.location.href = '/v2/index.html';

		return;
	};
	return (
		<>
			<Button
				type="tertiary"
				color="text-blue"
				width="medium"
				extraClasses={(organization.length > 0 ? `mr-0` : 'mr-4') + ' lt-md:hidden'}
				onClick={handleOpenModal}
			>
				{organizationName.length > 0 ? `${organizationName}` : `Assume Organization`}
			</Button>

			<MenuItem className="hidden lt-md:block" onClick={handleOpenModal}>
				{organizationName.length > 0 ? `${organizationName}` : `Assume Organization`}
			</MenuItem>
			{localStorage.getItem('isAssumedOrganization') && (
				<>
					<Button
						onClick={() => clearAssume()}
						type="tertiary"
						extraClasses="lt-md:hidden"
						color="text-blue"
						width="xs"
					>
						<Icon type="Close" classNames="w-3 -mt-1 inline-block icon-red" />
					</Button>
					<MenuItem className="hidden lt-md:block" onClick={clearAssume}>
						Reset Assumed Organization
					</MenuItem>
				</>
			)}
			<Modal
				maxWidth="sm"
				open={openModal}
				onClose={handleModalClose}
				classNames="overflow-visible"
				title="Choose Organization To Assume"
			>
				<div className="">
					<AsyncSelectField
						term="name"
						name="organizations"
						path="organizations"
						title="Organization"
						extraParams="&order_by=+name"
						disabled={netwrokOrganization.length > 0}
						onChange={(e, { data }) => {
							setOrgData(data);
							setOrganization(e);
							setHasSelected(e.length > 0);
							if (e.length === 0) {
								setStaff(() => 'admin');
							}
						}}
						selectedValue={organization}
						formatter={item => ({
							data: item,
							value: item.id,
							label: item.name,
						})}
						sendHeaders={false}
					/>
				</div>
				<div className="">
					<AsyncSelectField
						term="name"
						name="network_organizations"
						path="network_organizations"
						title="Network Organization"
						extraParams="&order_by=+name&inflate=true"
						disabled={organization.length > 0}
						onChange={(e, { data }) => {
							setNetworkOrgData(data);
							setNetwrokOrganization(e);
							setStaff(() => 'network_manager');
							setHasSelected(e.length > 0);
							if (e.length === 0) {
								setStaff(() => 'admin');
							}
						}}
						selectedValue={netwrokOrganization}
						formatter={item => ({
							data: item,
							value: item.id,
							label: item.name,
						})}
						sendHeaders={false}
					/>
				</div>
				<div>
					<SelectField
						list={STAFF_LIST.filter(e => {
							if (e.value === 'network_manager' && organization.length > 0) {
								return false;
							}
							return true;
						})}
						title="Choose Role"
						disabled={netwrokOrganization.length > 0}
						onChange={e => {
							setStaff(e);
						}}
						value={staff}
						name="staff"
					/>
				</div>
				<div className="text-right">
					<Button
						onClick={handleModalClose}
						type="tertiary"
						color="text-blue"
						width="small"
					>
						Cancel
					</Button>
					<Button
						hideLoader={true}
						disabled={(staff && staff.length === 0) || !hasSelected}
						onClick={handleOnClaimSelection}
						width="small"
					>
						Assume
					</Button>
					{netwrokOrganization.length > 0 &&
					window.location.host === 'stage.healthmedocs.com' ? (
						<Button
							hideLoader={true}
							disabled={(staff && staff.length === 0) || !hasSelected}
							onClick={handleOnClaimSelectionV2}
							width="medium"
						>
							Assume To New UI
						</Button>
					) : null}
				</div>
			</Modal>
		</>
	);
}
