import { ApiCore } from '@healthme/services/api/utilities/Core';
import { cacheApi2 } from '@healthme/utility/Utils';

import patientAccountsAPI from '../patient_accounts/Index';

/*
 **-------------------------------------------------------------------------------------
 ** METHOD NAME - Marketplaces Class
 **-------------------------------------------------------------------------------------
 */
class Marketplaces extends ApiCore {
	cache = {};
	/*
	 **-------------------------------------------------------------------------------------
	 ** FN NAME - formatForFilterList
	 **-------------------------------------------------------------------------------------
	 */
	formatForFilterList(data, ignoreAll = false) {
		const arr: any[] = [];
		if (!ignoreAll) arr.push({ label: 'All Marketplaces', value: 'all' });
		return [
			...arr,
			...data.data.map(item => {
				return {
					value: item.id,
					label: item.name,
				};
			}),
		];
	}
	/*
	 **-------------------------------------------------------------------------------------
	 ** FN NAME - addStaffToMarketPlace
	 **-------------------------------------------------------------------------------------
	 */

	addStaffToMarketPlace(marketPlaceId, staffId) {
		return this.post({}, `/${marketPlaceId}/staff_accounts/${staffId}`);
	}
	/*
	 **-------------------------------------------------------------------------------------
	 ** FN NAME - addProviderToMarketPlace
	 **-------------------------------------------------------------------------------------
	 */

	addProviderToMarketPlace(marketPlaceId, providerId) {
		return this.post({}, `/${marketPlaceId}/service_providers/${providerId}`);
	}
	/*
	 **-------------------------------------------------------------------------------------
	 ** FN NAME - getMarketplacePatients
	 **-------------------------------------------------------------------------------------
	 */

	getMarketplacePatients(marketPlaceId, q) {
		if (q === '?' || !q) q = `?marketplace_id=${marketPlaceId}`;
		else q = q + `&marketplace_id=${marketPlaceId}`;
		return patientAccountsAPI.get(`${q}`);
	}
	/*
	 **-------------------------------------------------------------------------------------
	 ** FN NAME - getMarketplacePatients
	 **-------------------------------------------------------------------------------------
	 */

	getNetworkPatients(marketPlaceId, q) {
		if (q === '?' || !q) q = `?network_id=${marketPlaceId}`;
		else q = q + `&network_id=${marketPlaceId}`;
		return patientAccountsAPI.get(`${q}`);
	}
	/*
	 **-------------------------------------------------------------------------------------
	 ** FN NAME - removeStaff
	 **-------------------------------------------------------------------------------------
	 */

	removeStaff(marketPlaceId, staffId) {
		return this.delete(`/${marketPlaceId}/staff_accounts/${staffId}`);
	}
	/*
	 **-------------------------------------------------------------------------------------
	 ** FN NAME - removeStaff
	 **-------------------------------------------------------------------------------------
	 */

	removeProvider(marketPlaceId, provider) {
		return this.delete(`/${marketPlaceId}/service_providers/${provider.id}`);
	}
	/*
	 **-------------------------------------------------------------------------------------
	 ** FN NAME - loadMarkplaceListings
	 **-------------------------------------------------------------------------------------
	 */
	async loadMarkplaceListings(ignore = false) {
		const pathArr = window.location.pathname.split('/').filter(e => e.length > 0);
		const orgSlug = pathArr[0];
		const marketplaceSlug = pathArr[1];
		const orgData = await cacheApi2(
			`public/organizations/${orgSlug}`,
			{},
			`package-${orgSlug}`
		);
		let marketplace = await cacheApi2(
			`public/marketplaces/${marketplaceSlug}`,
			{
				queryParams: {
					organization_id: orgData.id,
				},
			},
			`package-marketplaces-${marketplaceSlug}`
		);
		let packageTypes = null;
		let specialities = null;
		let providers = null;
		if (marketplaceSlug) {
			packageTypes = await cacheApi2(
				`public/marketplaces/${marketplace.id}/package_types`,
				{},
				'package_types'
			);

			if (!ignore) {
				specialities = await cacheApi2(
					`public/marketplaces/${marketplace.id}/specialities`,
					{ queryParams: { per_page: 50 } },
					'specialities'
				);
				const queryParams = {
					['marketplace_ids[in]']: marketplace.id,
					['per_page']: 50,
				};
				providers = await cacheApi2(
					`public/service_providers`,
					{ queryParams },
					'service_providers'
				);
			}
		}

		return {
			marketplace,
			packageTypes,
			specialities,
			providers: providers?.data,
			marketplaces,
			types: marketplace,
		};
	}
	/*
	 **-------------------------------------------------------------------------------------
	 ** FN NAME - getPublicPackagesByMarketplace
	 **-------------------------------------------------------------------------------------
	 */
	getPublicPackagesByMarketplace() {}
}

/*
 **-------------------------------------------------------------------------------------
 ** METHOD NAME - analytics
 **-------------------------------------------------------------------------------------
 */
const marketplaces = new Marketplaces({
	url: 'marketplaces',
});

export const marketplacesAdminAPI = new Marketplaces({
	url: 'admin/marketplaces',
});

export default marketplaces;
