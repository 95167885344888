import { ApiCore } from '@healthme/services/api/utilities/Core';

/*
 **-------------------------------------------------------------------------------------
 ** METHOD NAME - analytics Class
 **-------------------------------------------------------------------------------------
 */
class Messages extends ApiCore {}

/*
 **-------------------------------------------------------------------------------------
 ** METHOD NAME - analytics
 **-------------------------------------------------------------------------------------
 */
const messages = new Messages({
	url: 'messages',
});

export default messages;
