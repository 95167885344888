import classNames from 'classnames';
import { useFormik } from 'formik';
import ToggleButton from 'modules/patient/Components/Buttons/Toggle';
import * as React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import * as Yup from 'yup';

import Button from '@healthme/core/Button/Index';
import Divider from '@healthme/core/Divider/Index';
import TextField from '@healthme/core/Form/TextField/Index';
import Modal from '@healthme/core/Modal/Index';
import purchaseAPI from '@healthme/services/api/purchases/Index';

import { broadcastEvent, errorParser } from '../../utility/Utils';
import AsyncSelect from '../Form/SelectField/v2/AsyncSelect';
import { RefundRequestModal } from '../RequestRefundModal/Index';
import { ResendCodeModal } from '../ResendCodeModal/Index';
import SuccessMessage from '../SuccessMessage/Index';

export interface IClaimPaymentModalProps {
	openModal: boolean;
	payment?: any;
	hideRefund?: boolean;
	type?: string;
	onClose: () => void;
}

export function ClaimPaymentModal(props: IClaimPaymentModalProps) {
	const queryClient = useQueryClient();
	const asyncSelectRef = React.useRef(null);
	//USE STATE
	const [redeemError, setRedeemError] = React.useState('');
	const [hideModal, setHideModal] = React.useState(false);
	const [open, setOpen] = React.useState(false);
	const [formValues, setFormValues] = React.useState<any>({});
	const [openRequestRefundModal, setOpenRequestRefundModal] = React.useState(false);
	const [openResendCodeModal, setOpenResendCodeModal] = React.useState(false);
	const [successMessage, setSuccessMessage] = React.useState(false);
	const [message, setMessage] = React.useState('');
	const [disableICDOverride, setDisableICDOverride] = React.useState(true);
	const [buttonDisabled, setButtonDisabled] = React.useState(true);
	const [hideLoader, setHideLoader] = React.useState(true);
	const [isCareNav, setIsCareNav] = React.useState(props?.type === 'carenav');
	const [icdCodes, setICDCodes] = React.useState(
		props?.payment?.rest?.claimed_package?.package?.icd10 || ''
	);
	const [alertText, setAlertText] = React.useState(
		'Has the patient been treated by the Provider?'
	);
	const [icd10Override, setIcd10Override] = React.useState([]);
	//REACT QUERY
	const {
		mutate: mutateRedeem,
		data: redeemResponse,
		isLoading,
	} = useMutation((code: any) =>
		purchaseAPI.post(
			{},
			`/redeem?redemption_code=${code.toLowerCase().replace(/-/gi, '')}${
				icd10Override.length > 0 ? `&icd10_override=${icd10Override.join(',')}` : ''
			}`
		)
	);
	React.useEffect(() => {
		formik.setFieldValue('redemptionCode', props?.payment?.redemption_code || '');
	}, [props?.payment?.redemption_code]);

	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - formik
	 **-------------------------------------------------------------------------------------
	 */
	const formik = useFormik({
		validateOnChange: false,
		initialValues: {
			redemptionCode: '',
			icd10_override: '',
		},
		validationSchema: Yup.object({
			redemptionCode: Yup.string().required('Redemption Code required'),
		}),
		onSubmit: (values: any) => {
			setOpen(true);
			setFormValues(values);
			formik.resetForm();
		},
	});
	React.useEffect(() => {
		const sanitizedCodeLength = formik.values.redemptionCode.replace(
			/[^a-zA-Z0-9]/g,
			''
		).length;
		if (sanitizedCodeLength === 9) {
			setButtonDisabled(false);
			purchaseAPI
				.getRaw(
					`purchases?network=true&redemption_code=${formik.values.redemptionCode
						.toUpperCase()
						.replace(/-/gi, '')}`
				)
				.then(resp => {
					if (resp.data.length > 0) {
						if (resp.data[0].claimed_package?.package?.icd10) {
							setICDCodes(resp.data[0].claimed_package?.package?.icd10);
						}
						setIsCareNav(true);
					}
				});
		} else {
			setButtonDisabled(true);
		}
	}, [formik.values]);

	/*
	 **-------------------------------------------------------------------------------------
	 ** FN NAME - handleModalClose
	 **-------------------------------------------------------------------------------------
	 */
	const handleModalClose = () => {
		setRedeemError('');
		setOpenRequestRefundModal(false);

		formik.resetForm();
		props.onClose();
		setIsCareNav(false);
	};
	/*
	 **-------------------------------------------------------------------------------------
	 ** FN NAME - handleRefundModalClose
	 **-------------------------------------------------------------------------------------
	 */
	const handleRefundModalClose = () => {
		setOpenRequestRefundModal(false);
		setHideModal(false);
		formik.resetForm();
	};
	/*
	 **-------------------------------------------------------------------------------------
	 ** FN NAME - handleResendModalClose
	 **-------------------------------------------------------------------------------------
	 */
	const handleResendModalClose = () => {
		formik.resetForm();
		setOpenResendCodeModal(false);
		setHideModal(false);
	};
	/*
	 **-------------------------------------------------------------------------------------
	 ** FN NAME - handleNoResponse
	 **-------------------------------------------------------------------------------------
	 */
	const handleNoResponse = () => {
		setAlertText('Patients must be seen before packages can be redeemed.');
		queryClient.fetchQuery('marketplacea');
		queryClient.fetchQuery('claimed_packages');
		queryClient.fetchQuery('analytics');
		formik.resetForm();
	};
	/*
	 **-------------------------------------------------------------------------------------
	 ** FN NAME - handleYesResponse
	 **-------------------------------------------------------------------------------------
	 */
	const handleYesResponse = () => {
		setOpen(false);

		if (isCareNav && icd10Override.length === 0 && icdCodes.length === 0) {
			broadcastEvent('error', 'Please provide ICD-10 codes for this referral.');
			return;
		}
		mutateRedeem(formValues.redemptionCode, {
			onError: (err: any) => {
				setRedeemError(errorParser(err));
			},
			onSuccess: resp => {
				setMessage('This package was successfully redeemed.');
				queryClient.fetchQuery('marketplacea');
				queryClient.fetchQuery('claimed_packages');
				queryClient.fetchQuery('analytics');

				setSuccessMessage(true);
				handleModalClose();
				setIsCareNav(false);
				formik.resetForm();
			},
		});
	};
	return (
		<>
			{!hideModal && (
				<>
					<Modal
						title="Claim Payment"
						maxWidth="sm"
						open={props.openModal}
						onClose={handleModalClose}
					>
						<form onSubmit={formik.handleSubmit}>
							<div className={`pb-10`}>
								<p className="w-full m-auto text-center text-p">
									Patient will provide a 9-digit code from email after purchase.
								</p>
								<div className="flex flex-wrap justify-center mt-5">
									<div className="w-9/12">
										<TextField
											label="9-Digit Redemption Code"
											placeholder="XXXXXXXX"
											onChange={formik.handleChange}
											value={formik.values.redemptionCode}
											error={formik.errors.redemptionCode}
											name="redemptionCode"
											className="w-full"
											showMask
											mask="***-***-***"
										/>
									</div>
								</div>
								<p
									className={classNames(
										'w-full m-auto text-center text-p',
										isCareNav ? '' : 'hidden'
									)}
								>
									Please verify or change the packages associated ICD codes listed
									below.
								</p>
								<p
									className={classNames(
										'w-full m-auto text-center text-p',
										isCareNav ? '' : 'hidden'
									)}
								>
									{icdCodes
										.split(',')
										.filter(e => e.length > 0)
										.map(e => (
											<span
												key={e}
												className="inline-flex items-center mx-2.5 mt-2.5 gap-x-1.5 rounded-full bg-gray-100 px-2 py-1 text-sm font-medium text-gray-600"
											>
												<svg
													viewBox="0 0 6 6"
													aria-hidden="true"
													className="h-1.5 w-1.5 fill-gray-400"
												>
													<circle r={3} cx={3} cy={3} />
												</svg>
												{e}
											</span>
										)) || 'No ICD-10 Codes'}
								</p>
								<div
									className={classNames(
										'flex flex-col items-center justify-center mt-2.5',
										isCareNav ? '' : 'hidden'
									)}
								>
									<div className="mb-4">
										<ToggleButton
											text={'Override existing ICD codes for this referral'}
											onClick={e => {
												setDisableICDOverride(!e);
												console.log(e);
												if (!e) {
													setIcd10Override([]);
												}
											}}
											initialValue={false}
											className="text-p"
										/>
									</div>
									<div className="w-9/12">
										<AsyncSelect
											isMulti
											initialize={false}
											disabled={disableICDOverride}
											valueKey={'code'}
											name="icd10_override"
											placeholder="Select override ICD-10 codes"
											error={formik.errors.icd10_override}
											value={formik.values.icd10_override}
											label="Select override ICD-10 codes"
											onChange={e => {
												setIcd10Override(e.map((e: any) => e.value));
											}}
											formatter={e => ({
												value: e.code,
												label: `${e.code} - ${e.title}`,
											})}
											apiPath={`packages/icd10_codes`}
											menuPlacement="top"
										/>
									</div>
								</div>
								<div className="flex flex-wrap justify-center mt-5">
									<div className="w-9/12">
										<Button
											buttonType="submit"
											disabled={isLoading || buttonDisabled}
											type="primary"
											color="text-white"
											extraClasses="w-full max-w-full"
											hideLoader={hideLoader}
										>
											Claim Payment
										</Button>
									</div>
								</div>

								{!props.hideRefund && (
									<div className="flex flex-wrap justify-center mt-5 text-p">
										or &nbsp;
										<a
											onClick={() => {
												setOpenRequestRefundModal(true);
												setHideModal(true);
											}}
											className="text-link"
										>
											Request Refund
										</a>
									</div>
								)}
								{props.payment && (
									<>
										<Divider />
										<p className="w-8/12 m-auto font-semibold text-center text-p">
											Patient has not received code?
										</p>
										<div className="flex flex-wrap justify-center mt-5">
											<div className="w-9/12">
												<Button
													type="tertiary"
													color="text-blue"
													extraClasses="w-full max-w-full"
													onClick={() => {
														setOpenResendCodeModal(true);
														setHideModal(true);
													}}
												>
													Code Lookup &amp; Resend
												</Button>
											</div>
										</div>
									</>
								)}
							</div>
						</form>
					</Modal>
					<Modal maxWidth="xs" open={open} onClose={() => setOpen(false)}>
						<p className="mb-1 font-semibold text-center text-h3">Alert</p>
						<p className="w-8/12 m-auto mb-5 font-medium text-center text-p">
							{alertText}
						</p>
						<div className="flex flex-wrap justify-center gap-6 mt-0">
							<div className="w-5/12">
								<Button
									type="tertiary"
									color="text-blue"
									extraClasses="w-full max-w-full"
									onClick={handleNoResponse}
								>
									No
								</Button>
							</div>
							<div className="w-5/12">
								<Button
									type="primary"
									color="text-white"
									extraClasses="w-full max-w-full"
									onClick={handleYesResponse}
								>
									Yes
								</Button>
							</div>
						</div>
					</Modal>
					{successMessage && (
						<SuccessMessage
							message={message}
							open={successMessage}
							onClose={setSuccessMessage}
						/>
					)}
				</>
			)}

			{!props.hideRefund && (
				<RefundRequestModal
					openModal={openRequestRefundModal}
					onClose={handleRefundModalClose}
					showPreviousStep={true}
					payment={props.payment}
				/>
			)}

			{props.payment && (
				<ResendCodeModal
					openModal={openResendCodeModal}
					onClose={handleResendModalClose}
					showPreviousStep={true}
					payment={props.payment}
				/>
			)}
		</>
	);
}
