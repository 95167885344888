import clsx from 'clsx';
import React, { useEffect } from 'react';

import { amber, green } from '@material-ui/core/colors';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import { makeStyles } from '@material-ui/core/styles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';

import { HealthMeTheme } from '../../../types/AppContextPropsType';

type Variant = 'error' | 'info' | 'success' | 'warning';

const variantIcon = {
	success: CheckCircleIcon,
	warning: WarningIcon,
	error: ErrorIcon,
	info: InfoIcon,
};
const useStyles1 = makeStyles((theme: HealthMeTheme) => ({
	success: {
		backgroundColor: green[600],
	},
	error: {
		backgroundColor: theme.palette.error.dark,
	},
	info: {
		backgroundColor: theme.palette.primary.main,
	},
	warning: {
		backgroundColor: amber[700],
	},
	icon: {
		fontSize: 20,
	},
	iconVariant: {
		opacity: 0.9,
		marginRight: theme.spacing(1),
	},
	message: {
		display: 'flex',
		alignItems: 'center',
	},
}));

interface AppSnackbarProps {
	className?: string;
	message: string;
	variant: Variant;
	onClose?: () => void;
	[x: string]: any;
}

const AppSnackbar: React.FC<AppSnackbarProps> = ({
	className,
	message,
	variant,
	open,
	onClose = () => {},
	anchorOrigin = {
		vertical: 'bottom',
		horizontal: 'left',
	},
	...other
}) => {
	const classes = useStyles1();
	const Icon = variantIcon[variant];
	const [isOpen, setIsOpen] = React.useState(false);
	useEffect(() => {
		if (open === false) return;
		setIsOpen(open);
		setTimeout(() => {
			setIsOpen(false);
			if (onClose) onClose();
		}, 3000);
	}, [open]);

	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setIsOpen(false);
	};
	return (
		<Snackbar
			anchorOrigin={anchorOrigin}
			open={isOpen}
			autoHideDuration={3000}
			onClose={handleClose}
		>
			<SnackbarContent
				className={clsx(classes[variant], className)}
				aria-describedby="client-snackbar"
				message={
					<span id="client-snackbar" className={classes.message}>
						<Icon className={clsx(classes.icon, classes.iconVariant)} />
						{message}
					</span>
				}
				{...other}
			/>
		</Snackbar>
	);
};
export default AppSnackbar;
