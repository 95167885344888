import { useFormik } from 'formik';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';

import Button from '@healthme/core/Button/Index';
import PhoneField from '@healthme/core/Form/PhoneField/Index';
import TextField from '@healthme/core/Form/TextField/Index';
import Modal from '@healthme/core/Modal/Index';
import marketplaceAPI from '@healthme/services/api/marketplaces/Index';
import networksAPI from '@healthme/services/api/networks/Index';
import patientAPI from '@healthme/services/api/patient_accounts/Index';
import userService from '@healthme/services/user';
import { broadcastEvent, isNetworkManager, sortArrayOfObjects } from '@healthme/utility/Utils';

import Icon from '../Icon/Index';

const serialize = obj => {
	var str: any[] = [];
	for (let p in obj)
		if (obj.hasOwnProperty(p)) {
			str.push(p + '=' + obj[p]);
		}
	return str.join('&');
};

export interface IPatientLookupModalProps {
	openModal: boolean;
	onClose: () => void;
}
const user: any = userService.getUser();
export function PatientLookupModal(props: IPatientLookupModalProps) {
	//USE STATE
	const history = useHistory();
	const [searchError, setSearchError] = React.useState('');
	const [marketplaces, setMarketplaces] = React.useState([]);
	const [isLoading, setIsLoading] = React.useState(false);
	const [isNetwork, setIsNetwork] = React.useState(isNetworkManager());
	React.useEffect(() => {
		if (!props.openModal) return;

		if (user.role === 'network_manager') {
			networksAPI
				.getRaw(`network_organizations/${user.network_organization_id}/marketplaces`, {
					queryParams: { per_page: 50 },
				})
				.then(resp => {
					setMarketplaces(
						resp.data
							.map(item => {
								return { value: item.id, label: item.name };
							})
							.sort(sortArrayOfObjects('label'))
					);
					if (resp.data.length == 1) {
						formik.setValues({
							...formik.values,
							marketplaces: resp.data[0].id,
						});
					}
				});
		}

		if (user.role !== 'network_manager') {
			marketplaceAPI.getAll({ queryParams: { page_size: '100' } }).then(resp => {
				setMarketplaces(
					resp.data
						.map(item => {
							return { value: item.id, label: item.name };
						})
						.sort(sortArrayOfObjects('label'))
				);
				if (resp.data.length == 1) {
					formik.setValues({
						...formik.values,
						marketplaces: resp.data[0].id,
					});
				}
			});
		}
	}, [props.openModal]);

	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - formik
	 **-------------------------------------------------------------------------------------
	 */
	const formik = useFormik({
		validateOnChange: false,
		initialValues: {
			marketplaces: '',
			email: '',
			phone: '',
			lastName: '',
			redemptionCode: '',
		},
		validationSchema: Yup.object({
			email: Yup.string().email(),
			phone: Yup.string(),
		}),
		onSubmit: async (data: any) => {
			setIsLoading(true);
			const query = {};
			if (data.email.length) {
				query['email[like]'] = encodeURIComponent(data.email);
			}
			if (data.phone.length) {
				query['patient_phone[like]'] = encodeURIComponent(data.phone);
			}
			if (data.lastName.length) {
				query['patient_last_name[ilike]'] = encodeURIComponent(data.lastName);
			}
			if (data.redemptionCode.length) {
				query['redemption_code'] = encodeURIComponent(data.redemptionCode);
			}
			const resp = await patientAPI.getAll({ queryParams: query });
			setIsLoading(false);
			console.log(resp.data);
			console.log(user);

			//.then(resp => {

			if (!resp.data.length) {
				broadcastEvent('error', 'No Search Results Found.');
				return;
			}
			setSearchError('');

			if (isNetwork) {
				if (resp.data.length === 1)
					history.push(`/secure/patients/view/${resp.data[0].id}`);
				if (resp.data.length > 1)
					window.location.href = `/secure/networks/accounts?activeTab=patients&${serialize(
						query
					).replace('?', '')}`;
				handleModalClose();

				return;
			}

			if (resp.data.length === 1) history.push(`/secure/patients/view/${resp.data[0].id}`);
			if (resp.data.length > 1)
				window.location.href = `/secure/patients/list?${serialize(query).replace('?', '')}`;
			handleModalClose();
		},
	});
	/*
	 **-------------------------------------------------------------------------------------
	 ** FN NAME - handleModalClose
	 **-------------------------------------------------------------------------------------
	 */
	const handleModalClose = () => {
		formik.resetForm();
		setSearchError('');
		props.onClose();
	};

	return (
		<>
			<Modal
				maxWidth="xs"
				open={props.openModal}
				onClose={handleModalClose}
				title="Patient Lookup"
			>
				<form onSubmit={formik.handleSubmit}>
					<div className="">
						<p className="w-8/12 m-auto text-center text-p">Provide at least one.</p>
						<div className="flex flex-wrap justify-center mt-1">
							<div className="w-9/12">
								<TextField
									label="Last Name"
									placeholder=" "
									onChange={formik.handleChange}
									value={formik.values.lastName}
									error={formik.errors.lastName}
									name="lastName"
									className="w-full"
								/>
							</div>
						</div>

						<div className="flex flex-wrap justify-center mt-1">
							<div className="w-9/12">
								<TextField
									label="Email"
									placeholder=" "
									onChange={formik.handleChange}
									value={formik.values.email}
									error={formik.errors.email}
									name="email"
									className="w-full"
								/>
							</div>
						</div>
						<div className="flex flex-wrap justify-center mt-1">
							<div className="w-9/12 ">
								<PhoneField
									label="Phone"
									placeholder="Phone"
									onChange={e => formik.setFieldValue('phone', `${e}`)}
									value={formik.values.phone}
									error={formik.errors.phone as any}
									name="phone"
									className="w-full"
								/>
							</div>
						</div>
						<div className="flex flex-wrap justify-center mt-1">
							<div className="w-9/12 mb-5">
								<TextField
									label="9-Digit Redemption Code"
									placeholder="XXXXXXXX"
									onChange={formik.handleChange}
									value={formik.values.redemptionCode}
									error={formik.errors.redemptionCode}
									name="redemptionCode"
									className="w-full"
									showMask
									mask="***-***-***"
								/>
							</div>
						</div>
						<div className="flex flex-wrap justify-center mt-0">
							<div className="w-9/12">
								<Button
									disabled={isLoading}
									buttonType="submit"
									type="primary"
									color="text-white"
									extraClasses="w-full max-w-full"
								>
									<Icon
										type="Search"
										classNames="w-4 inline-block mr-2 icon-white"
									/>{' '}
									Search Now
								</Button>
							</div>
						</div>
					</div>
				</form>
			</Modal>
		</>
	);
}
