class User {
	/*
	 **-------------------------------------------------------------------------------------
	 ** FN NAME - getUser
	 **-------------------------------------------------------------------------------------
	 */
	getUser() {
		try {
			return JSON.parse(atob(localStorage.getItem('user') as string));
		} catch (e) {
			return {};
		}
	}
	/*
	 **-------------------------------------------------------------------------------------
	 ** FN NAME - setUser
	 **-------------------------------------------------------------------------------------
	 */
	setUser(data) {
		localStorage.setItem('user', btoa(JSON.stringify(data)));
	}
	/*
	 **-------------------------------------------------------------------------------------
	 ** FN NAME - getOrganizationId
	 **-------------------------------------------------------------------------------------
	 */
	getOrganizationId() {
		return (
			localStorage.getItem('organization') ||
			JSON.parse(atob(localStorage.getItem('user') as string)).organization_id
		);
	}
}

const user = new User();

export { user as userService };

export default user;